import { Button } from "@mui/material";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { NiceIdContext } from "../../context/NiceIdContext/NiceIdContext";
import { QueueDataContext } from "../../context/QueueDataContext/QueueDataContext";
import "./NiceIdButton.scss";
interface Props {
}

const NiceIdButton: React.FC<Props> = () => {
  const { t } = useTranslation();

  const { niceIdEncodedData } = useContext(NiceIdContext);
  const { authenticated } = useContext(QueueDataContext); 

  window.name ="Parent_window";

  const popup = () => {
    window.open('', 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');
		const form: HTMLFormElement = document.getElementById("form") as HTMLFormElement;
    form.action = "https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb";
    form.target = "popupChk"
		form.submit();
  }

  return (
    <>
      <form id="form" name="form_chk" method="post">
        <input type="hidden" name="m" value="checkplusService" />			
        <input type="hidden" name="EncodeData" value={niceIdEncodedData} />
      </form>

      <Button
        variant={"contained"}
        color={"secondary"}
        className={"half-width-field"}
        onClick={() => {popup()}}
        data-testid="bottom-button"
        disabled={authenticated}
      >{t("DETAILS_AUTHENTICATE_BUTTON")}</Button>
    </>
  );
};

export default NiceIdButton;
