import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import routerMapping from "../../constants/mappings/routerMapping";
import { KakaoAuthContext } from "../KakaoAuthContext/KakaoAuthContext";
import { NiceIdContext } from "../NiceIdContext/NiceIdContext";
import { StoreContext } from "../StoreContext/StoreContext";
interface Props {
  children: React.ReactNode;
}

interface RouterContextInterface {
  from: string;
  to: string;
}

export const RouterContext = createContext<RouterContextInterface>({} as RouterContextInterface);

const RouterContextProvider: React.FC<Props> = ({ children }) => {
  const location = useLocation();
  const history = useHistory();
  const { validateKakaoToken } = useContext(KakaoAuthContext);
  const { validateNiceIdToken } = useContext(NiceIdContext);

  const { setTokenIsValid } = useContext(StoreContext);

  const [route, setRoute] = useState({
    to: location.pathname,
    from: location.pathname,
  });

  const checkNavigationValidity = useCallback(async (to: string, from: string) => {
    if (to === "/kiosk") {
      return;
    }

    let validExternalFlow = false;
    if (to === "/details") {
      const kakaoToken = new URLSearchParams(history.location.search).get("kakaoToken");
      const niceIdToken = new URLSearchParams(history.location.search).get("niceIdToken");

      if ((kakaoToken && validateKakaoToken(kakaoToken)) || (niceIdToken && validateNiceIdToken(niceIdToken))) {
        validExternalFlow = true;
        setTokenIsValid(true);
      } 
    }

    const mapping = routerMapping.find(x => x.route === to);
    if (!mapping || (!mapping.predecessors.includes(from) && !validExternalFlow)) {
      history.push("/error?code=002");
    }
  }, [history, setTokenIsValid, validateKakaoToken, validateNiceIdToken]);

  useEffect(() => {
    setRoute((prev) => ({ to: location.pathname, from: prev.to }));
  }, [location]);

  useEffect(() => {
    checkNavigationValidity(route.to, route.from);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route]);

  return <RouterContext.Provider value={route}>{children}</RouterContext.Provider>;
};

export default RouterContextProvider;
