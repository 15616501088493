export const CountryNameMappingPreferred = [
  {
    iso: "KOR",
    enLabel: "South Korea",
    krLabel: "대한민국",
  },
];

const CountryNameMapping = [
  {
    iso: "AND",
    enLabel: "Andorra",
    krLabel: "안도라",
  },
  {
    iso: "ARE",
    enLabel: "United Arab Emirates",
    krLabel: "아랍 에미리트",
  },
  {
    iso: "AFG",
    enLabel: "Afghanistan",
    krLabel: "아프가니스탄",
  },
  {
    iso: "ATG",
    enLabel: "Antigua and Barbuda",
    krLabel: "앤티가 바부 다",
  },
  {
    iso: "AIA",
    enLabel: "Anguilla",
    krLabel: "앵 귈라",
  },
  {
    iso: "ALB",
    enLabel: "Albania",
    krLabel: "알바니아",
  },
  {
    iso: "ARM",
    enLabel: "Armenia",
    krLabel: "아르메니아",
  },
  {
    iso: "ANT",
    enLabel: "Netherlands Antilles",
    krLabel: "네덜란드령 안틸레스",
  },
  {
    iso: "AGO",
    enLabel: "Angola",
    krLabel: "앙골라",
  },
  {
    iso: "ATA",
    enLabel: "Antarctica",
    krLabel: "남극 대륙",
  },
  {
    iso: "ARG",
    enLabel: "Argentina",
    krLabel: "아르헨티나",
  },
  {
    iso: "ASM",
    enLabel: "American Samoa",
    krLabel: "아메리칸 사모아",
  },
  {
    iso: "AUT",
    enLabel: "Austria",
    krLabel: "오스트리아",
  },
  {
    iso: "AUS",
    enLabel: "Australia",
    krLabel: "호주",
  },
  {
    iso: "ABW",
    enLabel: "Aruba",
    krLabel: "아루바",
  },
  {
    iso: "ALA",
    enLabel: "Åland Islands",
    krLabel: "올란드 제도",
  },
  {
    iso: "AZE",
    enLabel: "Azerbaijan",
    krLabel: "아제르바이잔",
  },
  {
    iso: "BIH",
    enLabel: "Bosnia and Herzegovina",
    krLabel: "보스니아 헤르체고비나",
  },
  {
    iso: "BRB",
    enLabel: "Barbados",
    krLabel: "바베이도스",
  },
  {
    iso: "BGD",
    enLabel: "Bangladesh",
    krLabel: "방글라데시",
  },
  {
    iso: "BEL",
    enLabel: "Belgium",
    krLabel: "벨기에",
  },
  {
    iso: "BFA",
    enLabel: "Burkina Faso",
    krLabel: "부키나 파소",
  },
  {
    iso: "BGR",
    enLabel: "Bulgaria",
    krLabel: "불가리아",
  },
  {
    iso: "BHR",
    enLabel: "Bahrain",
    krLabel: "바레인",
  },
  {
    iso: "BDI",
    enLabel: "Burundi",
    krLabel: "부룬디",
  },
  {
    iso: "BEN",
    enLabel: "Benin",
    krLabel: "베냉",
  },
  {
    iso: "BLM",
    enLabel: "Saint Barthélemy",
    krLabel: "성 바르텔레미",
  },
  {
    iso: "BMU",
    enLabel: "Bermuda",
    krLabel: "버뮤다",
  },
  {
    iso: "BRN",
    enLabel: "Brunei",
    krLabel: "브루나이 다루살람",
  },
  {
    iso: "BOL",
    enLabel: "Bolivia",
    krLabel: "볼리비아, 다민족 국가",
  },
  {
    iso: "BRA",
    enLabel: "Brazil",
    krLabel: "브라질",
  },
  {
    iso: "BHS",
    enLabel: "Bahamas",
    krLabel: "바하마",
  },
  {
    iso: "BTN",
    enLabel: "Bhutan",
    krLabel: "부탄",
  },
  {
    iso: "BES",
    enLabel: "Bonaire, Sint Eustatius and Saba",
    krLabel: "보네르, 신트 유스 타티 우스, 사바",
  },
  {
    iso: "BVT",
    enLabel: "Bouvet Island",
    krLabel: "부베 섬",
  },
  {
    iso: "BWA",
    enLabel: "Botswana",
    krLabel: "보츠와나",
  },
  {
    iso: "BLR",
    enLabel: "Belarus",
    krLabel: "벨라루스",
  },
  {
    iso: "BLZ",
    enLabel: "Belize",
    krLabel: "벨리즈",
  },
  {
    iso: "CAN",
    enLabel: "Canada",
    krLabel: "캐나다",
  },
  {
    iso: "CCK",
    enLabel: "Cocos (Keeling) Islands",
    krLabel: "코코스 군도",
  },
  {
    iso: "COD",
    enLabel: "Congo, the Democratic Republic of the",
    krLabel: "콩고 민주 공화국",
  },
  {
    iso: "CAF",
    enLabel: "Central African Republic",
    krLabel: "중앙 아프리카 공화국",
  },
  {
    iso: "COG",
    enLabel: "Congo",
    krLabel: "콩고",
  },
  {
    iso: "CHE",
    enLabel: "Switzerland",
    krLabel: "스위스",
  },
  {
    iso: "CIV",
    enLabel: "Ivory Coast",
    krLabel: "코트 디부아르",
  },
  {
    iso: "COK",
    enLabel: "Cook Islands",
    krLabel: "쿡 제도",
  },
  {
    iso: "CHL",
    enLabel: "Chile",
    krLabel: "칠레",
  },
  {
    iso: "CMR",
    enLabel: "Cameroon",
    krLabel: "카메룬",
  },
  {
    iso: "CHN",
    enLabel: "Mainland China",
    krLabel: "중국",
  },
  {
    iso: "COL",
    enLabel: "Colombia",
    krLabel: "콜롬비아",
  },
  {
    iso: "CRI",
    enLabel: "Costa Rica",
    krLabel: "코스타리카",
  },
  {
    iso: "CUB",
    enLabel: "Cuba",
    krLabel: "쿠바",
  },
  {
    iso: "CPV",
    enLabel: "Cape Verde",
    krLabel: "카보 베르데",
  },
  {
    iso: "CUW",
    enLabel: "Curaçao",
    krLabel: "쿠라 사오",
  },
  {
    iso: "CXR",
    enLabel: "Christmas Island",
    krLabel: "크리스마스 섬",
  },
  {
    iso: "CYP",
    enLabel: "Cyprus",
    krLabel: "사이프러스",
  },
  {
    iso: "CZE",
    enLabel: "Czech Republic",
    krLabel: "체코",
  },
  {
    iso: "DEU",
    enLabel: "Germany",
    krLabel: "독일",
  },
  {
    iso: "DJI",
    enLabel: "Djibouti",
    krLabel: "지부티",
  },
  {
    iso: "DNK",
    enLabel: "Denmark",
    krLabel: "덴마크",
  },
  {
    iso: "DMA",
    enLabel: "Dominica",
    krLabel: "도미니카",
  },
  {
    iso: "DOM",
    enLabel: "Dominican Republic",
    krLabel: "도미니카 공화국",
  },
  {
    iso: "DZA",
    enLabel: "Algeria",
    krLabel: "알제리",
  },
  {
    iso: "ECU",
    enLabel: "Ecuador",
    krLabel: "에콰도르",
  },
  {
    iso: "EST",
    enLabel: "Estonia",
    krLabel: "에스토니아",
  },
  {
    iso: "EGY",
    enLabel: "Egypt",
    krLabel: "이집트",
  },
  {
    iso: "ESH",
    enLabel: "Western Sahara",
    krLabel: "서사하라",
  },
  {
    iso: "ERI",
    enLabel: "Eritrea",
    krLabel: "에리트레아",
  },
  {
    iso: "ESP",
    enLabel: "Spain",
    krLabel: "스페인",
  },
  {
    iso: "ETH",
    enLabel: "Ethiopia",
    krLabel: "에티오피아",
  },
  {
    iso: "FIN",
    enLabel: "Finland",
    krLabel: "핀란드",
  },
  {
    iso: "FJI",
    enLabel: "Fiji",
    krLabel: "피지",
  },
  {
    iso: "FLK",
    enLabel: "Falkland Islands (Malvinas)",
    krLabel: "포클랜드 제도 (말 비나 스)",
  },
  {
    iso: "FSM",
    enLabel: "Micronesia",
    krLabel: "미크로네시아",
  },
  {
    iso: "FRO",
    enLabel: "Faroe Islands",
    krLabel: "페로 제도",
  },
  {
    iso: "FRA",
    enLabel: "France",
    krLabel: "프랑스",
  },
  {
    iso: "GAB",
    enLabel: "Gabon",
    krLabel: "가봉",
  },
  {
    iso: "GBR",
    enLabel: "United Kingdom",
    krLabel: "영국",
  },
  {
    iso: "GRD",
    enLabel: "Grenada",
    krLabel: "그레나다",
  },
  {
    iso: "GEO",
    enLabel: "Georgia",
    krLabel: "그루지야",
  },
  {
    iso: "GUF",
    enLabel: "French Guiana",
    krLabel: "프랑스령 기아나",
  },
  {
    iso: "GGY",
    enLabel: "Guernsey",
    krLabel: "건지 섬",
  },
  {
    iso: "GHA",
    enLabel: "Ghana",
    krLabel: "가나",
  },
  {
    iso: "GIB",
    enLabel: "Gibraltar",
    krLabel: "지브롤터",
  },
  {
    iso: "GRL",
    enLabel: "Greenland",
    krLabel: "그린란드",
  },
  {
    iso: "GMB",
    enLabel: "Gambia",
    krLabel: "감비아",
  },
  {
    iso: "GIN",
    enLabel: "Guinea",
    krLabel: "기니",
  },
  {
    iso: "GLP",
    enLabel: "Guadeloupe",
    krLabel: "과들루프",
  },
  {
    iso: "GNQ",
    enLabel: "Equatorial Guinea",
    krLabel: "적도 기니",
  },
  {
    iso: "GRC",
    enLabel: "Greece",
    krLabel: "그리스",
  },
  {
    iso: "SGS",
    enLabel: "South Georgia and the South Sandwich Islands",
    krLabel: "사우스 조지아 및 사우스 샌드위치 제도",
  },
  {
    iso: "GTM",
    enLabel: "Guatemala",
    krLabel: "과테말라",
  },
  {
    iso: "GUM",
    enLabel: "Guam",
    krLabel: "괌",
  },
  {
    iso: "GNB",
    enLabel: "Guinea-Bissau",
    krLabel: "기니 비사우",
  },
  {
    iso: "GUY",
    enLabel: "Guyana",
    krLabel: "가이아나",
  },
  {
    iso: "HKG",
    enLabel: "Hong Kong S.A.R.",
    krLabel: "홍콩 S.A.R.",
  },
  {
    iso: "HMD",
    enLabel: "Heard Island and McDonald Islands",
    krLabel: "허드 섬 및 맥도널드 제도",
  },
  {
    iso: "HND",
    enLabel: "Honduras",
    krLabel: "온두라스",
  },
  {
    iso: "HRV",
    enLabel: "Croatia",
    krLabel: "크로아티아",
  },
  {
    iso: "HTI",
    enLabel: "Haiti",
    krLabel: "아이티",
  },
  {
    iso: "HUN",
    enLabel: "Hungary",
    krLabel: "헝가리",
  },
  {
    iso: "IDN",
    enLabel: "Indonesia",
    krLabel: "인도네시아 공화국",
  },
  {
    iso: "IRL",
    enLabel: "Ireland",
    krLabel: "아일랜드",
  },
  {
    iso: "ISR",
    enLabel: "Israel",
    krLabel: "이스라엘",
  },
  {
    iso: "IMN",
    enLabel: "Isle of Man",
    krLabel: "아일 오브 맨",
  },
  {
    iso: "IND",
    enLabel: "India",
    krLabel: "인도",
  },
  {
    iso: "IOT",
    enLabel: "British Indian Ocean Territory",
    krLabel: "영국령 인도양 지역",
  },
  {
    iso: "IRQ",
    enLabel: "Iraq",
    krLabel: "이라크",
  },
  {
    iso: "IRN",
    enLabel: "Iran",
    krLabel: "이란, 이슬람 공화국",
  },
  {
    iso: "ISL",
    enLabel: "Iceland",
    krLabel: "아이슬란드",
  },
  {
    iso: "ITA",
    enLabel: "Italy",
    krLabel: "이탈리아",
  },
  {
    iso: "JEY",
    enLabel: "Jersey",
    krLabel: "저지",
  },
  {
    iso: "JAM",
    enLabel: "Jamaica",
    krLabel: "자메이카",
  },
  {
    iso: "JOR",
    enLabel: "Jordan",
    krLabel: "요르단",
  },
  {
    iso: "JPN",
    enLabel: "Japan",
    krLabel: "일본",
  },
  {
    iso: "KEN",
    enLabel: "Kenya",
    krLabel: "케냐",
  },
  {
    iso: "KGZ",
    enLabel: "Kyrgyzstan",
    krLabel: "키르기스스탄",
  },
  {
    iso: "KHM",
    enLabel: "Cambodia",
    krLabel: "캄보디아",
  },
  {
    iso: "KIR",
    enLabel: "Kiribati",
    krLabel: "키리바시",
  },
  {
    iso: "COM",
    enLabel: "Comoros",
    krLabel: "코모로",
  },
  {
    iso: "KNA",
    enLabel: "Saint Kitts and Nevis",
    krLabel: "세인트 키츠 네비스",
  },
  {
    iso: "PRK",
    enLabel: "North Korea",
    krLabel: "북한",
  },
  {
    iso: "KOR",
    enLabel: "South Korea",
    krLabel: "대한민국",
  },
  {
    iso: "KWT",
    enLabel: "Kuwait",
    krLabel: "쿠웨이트",
  },
  {
    iso: "CYM",
    enLabel: "Cayman Islands",
    krLabel: "케이맨 군도",
  },
  {
    iso: "KAZ",
    enLabel: "Kazakhstan",
    krLabel: "카자흐스탄",
  },
  {
    iso: "LAO",
    enLabel: "Lao People's Democratic Republic",
    krLabel: "라오스",
  },
  {
    iso: "LBN",
    enLabel: "Lebanon",
    krLabel: "레바논",
  },
  {
    iso: "LCA",
    enLabel: "Saint Lucia",
    krLabel: "세인트 루시아",
  },
  {
    iso: "LIE",
    enLabel: "Liechtenstein",
    krLabel: "리히텐슈타인",
  },
  {
    iso: "LKA",
    enLabel: "Sri Lanka",
    krLabel: "스리랑카",
  },
  {
    iso: "LBR",
    enLabel: "Liberia",
    krLabel: "라이베리아",
  },
  {
    iso: "LSO",
    enLabel: "Lesotho",
    krLabel: "레소토",
  },
  {
    iso: "LTU",
    enLabel: "Lithuania",
    krLabel: "리투아니아",
  },
  {
    iso: "LUX",
    enLabel: "Luxembourg",
    krLabel: "룩셈부르크",
  },
  {
    iso: "LVA",
    enLabel: "Latvia",
    krLabel: "라트비아",
  },
  {
    iso: "LBY",
    enLabel: "Libya",
    krLabel: "리비아",
  },
  {
    iso: "MAR",
    enLabel: "Morocco",
    krLabel: "모로코",
  },
  {
    iso: "MCO",
    enLabel: "Monaco",
    krLabel: "모나코",
  },
  {
    iso: "MDA",
    enLabel: "Moldova",
    krLabel: "몰도바 공화국",
  },
  {
    iso: "MNE",
    enLabel: "Montenegro",
    krLabel: "몬테네그로",
  },
  {
    iso: "MAF",
    enLabel: "Saint Martin (French part)",
    krLabel: "생 마르탕 (프랑스령)",
  },
  {
    iso: "MDG",
    enLabel: "Madagascar",
    krLabel: "마다가스카르",
  },
  {
    iso: "MHL",
    enLabel: "Marshall Islands",
    krLabel: "마샬 군도",
  },
  {
    iso: "MKD",
    enLabel: "Macedonia",
    krLabel: "마케도니아, 전 유고 슬라비아 공화국",
  },
  {
    iso: "MLI",
    enLabel: "Mali",
    krLabel: "말리",
  },
  {
    iso: "MMR",
    enLabel: "Myanmar",
    krLabel: "미얀마",
  },
  {
    iso: "MNG",
    enLabel: "Mongolia",
    krLabel: "몽골리아",
  },
  {
    iso: "MAC",
    enLabel: "Macau S.A.R.",
    krLabel: "마카오 S.A.R.",
  },
  {
    iso: "MNP",
    enLabel: "Northern Mariana Islands",
    krLabel: "북 마리아나 군도",
  },
  {
    iso: "MTQ",
    enLabel: "Martinique",
    krLabel: "마르티니크",
  },
  {
    iso: "MRT",
    enLabel: "Mauritania",
    krLabel: "모리타니",
  },
  {
    iso: "MSR",
    enLabel: "Montserrat",
    krLabel: "몬세라트",
  },
  {
    iso: "MLT",
    enLabel: "Malta",
    krLabel: "몰타",
  },
  {
    iso: "MUS",
    enLabel: "Mauritius",
    krLabel: "모리셔스",
  },
  {
    iso: "MDV",
    enLabel: "Maldives",
    krLabel: "몰디브",
  },
  {
    iso: "MWI",
    enLabel: "Malawi",
    krLabel: "말라위",
  },
  {
    iso: "MEX",
    enLabel: "Mexico",
    krLabel: "멕시코",
  },
  {
    iso: "MYS",
    enLabel: "Malaysia",
    krLabel: "말레이시아",
  },
  {
    iso: "MOZ",
    enLabel: "Mozambique",
    krLabel: "모잠비크",
  },
  {
    iso: "NAM",
    enLabel: "Namibia",
    krLabel: "나미비아",
  },
  {
    iso: "NCL",
    enLabel: "New Caledonia",
    krLabel: "뉴 칼레도니아",
  },
  {
    iso: "NER",
    enLabel: "Niger",
    krLabel: "니제르",
  },
  {
    iso: "NFK",
    enLabel: "Norfolk Island",
    krLabel: "노퍽 섬",
  },
  {
    iso: "NGA",
    enLabel: "Nigeria",
    krLabel: "나이지리아",
  },
  {
    iso: "NIC",
    enLabel: "Nicaragua",
    krLabel: "니카라과",
  },
  {
    iso: "NLD",
    enLabel: "Netherlands",
    krLabel: "네덜란드",
  },
  {
    iso: "NOR",
    enLabel: "Norway",
    krLabel: "노르웨이",
  },
  {
    iso: "NPL",
    enLabel: "Nepal",
    krLabel: "네팔",
  },
  {
    iso: "NRU",
    enLabel: "Nauru",
    krLabel: "나우루",
  },
  {
    iso: "NIU",
    enLabel: "Niue",
    krLabel: "니우에",
  },
  {
    iso: "NZL",
    enLabel: "New Zealand",
    krLabel: "뉴질랜드",
  },
  {
    iso: "OMN",
    enLabel: "Oman",
    krLabel: "오만",
  },
  {
    iso: "PAN",
    enLabel: "Panama",
    krLabel: "파나마",
  },
  {
    iso: "PER",
    enLabel: "Peru",
    krLabel: "페루",
  },
  {
    iso: "PYF",
    enLabel: "French Polynesia",
    krLabel: "프랑스령 폴리네시아",
  },
  {
    iso: "PNG",
    enLabel: "Papua New Guinea",
    krLabel: "파푸아 뉴기니",
  },
  {
    iso: "PHL",
    enLabel: "Philippines",
    krLabel: "필리핀 제도",
  },
  {
    iso: "PAK",
    enLabel: "Pakistan",
    krLabel: "파키스탄",
  },
  {
    iso: "POL",
    enLabel: "Poland",
    krLabel: "폴란드",
  },
  {
    iso: "SPM",
    enLabel: "Saint Pierre and Miquelon",
    krLabel: "생 피에르 미클롱",
  },
  {
    iso: "PCN",
    enLabel: "Pitcairn",
    krLabel: "핏 케언 제도",
  },
  {
    iso: "PRI",
    enLabel: "Puerto Rico",
    krLabel: "푸에르토 리코",
  },
  {
    iso: "PSE",
    enLabel: "Palestinian Territory",
    krLabel: "팔레스타인",
  },
  {
    iso: "PRT",
    enLabel: "Portugal",
    krLabel: "포르투갈",
  },
  {
    iso: "PLW",
    enLabel: "Palau",
    krLabel: "팔라우",
  },
  {
    iso: "PRY",
    enLabel: "Paraguay",
    krLabel: "파라과이",
  },
  {
    iso: "QAT",
    enLabel: "Qatar",
    krLabel: "카타르",
  },
  {
    iso: "REU",
    enLabel: "Reunion",
    krLabel: "재결합",
  },
  {
    iso: "ROU",
    enLabel: "Romania",
    krLabel: "루마니아",
  },
  {
    iso: "SRB",
    enLabel: "Serbia",
    krLabel: "세르비아",
  },
  {
    iso: "RUS",
    enLabel: "Russia",
    krLabel: "러시아 연방",
  },
  {
    iso: "RWA",
    enLabel: "Rwanda",
    krLabel: "르완다",
  },
  {
    iso: "SAU",
    enLabel: "Saudi Arabia",
    krLabel: "사우디 아라비아",
  },
  {
    iso: "SLB",
    enLabel: "Solomon Islands",
    krLabel: "솔로몬 제도",
  },
  {
    iso: "SYC",
    enLabel: "Seychelles",
    krLabel: "세이셸",
  },
  {
    iso: "SDN",
    enLabel: "Sudan",
    krLabel: "수단",
  },
  {
    iso: "SWE",
    enLabel: "Sweden",
    krLabel: "스웨덴",
  },
  {
    iso: "SGP",
    enLabel: "Singapore",
    krLabel: "싱가포르",
  },
  {
    iso: "SHN",
    enLabel: "Saint Helena, Ascension and Tristan da Cunha",
    krLabel: "세인트헬레나 어센션 트리스탄다쿠냐",
  },
  {
    iso: "SVN",
    enLabel: "Slovenia",
    krLabel: "슬로베니아",
  },
  {
    iso: "SJM",
    enLabel: "Svalbard and Jan Mayen",
    krLabel: "스발바르와 얀 메이엔",
  },
  {
    iso: "SVK",
    enLabel: "Slovakia",
    krLabel: "슬로바키아",
  },
  {
    iso: "SLE",
    enLabel: "Sierra Leone",
    krLabel: "시에라 리온",
  },
  {
    iso: "SMR",
    enLabel: "San Marino",
    krLabel: "산 마리노",
  },
  {
    iso: "SEN",
    enLabel: "Senegal",
    krLabel: "세네갈",
  },
  {
    iso: "SOM",
    enLabel: "Somalia",
    krLabel: "소말리아",
  },
  {
    iso: "SUR",
    enLabel: "Suriname",
    krLabel: "수리남",
  },
  {
    iso: "SSD",
    enLabel: "South Sudan",
    krLabel: "남 수단",
  },
  {
    iso: "STP",
    enLabel: "Sao Tome and Principe",
    krLabel: "상투 메 프린시 페",
  },
  {
    iso: "SLV",
    enLabel: "El Salvador",
    krLabel: "엘살바도르",
  },
  {
    iso: "SXM",
    enLabel: "Sint Maarten (Dutch part)",
    krLabel: "신트 마틴 (네덜란드령)",
  },
  {
    iso: "SYR",
    enLabel: "Syria",
    krLabel: "시리아",
  },
  {
    iso: "SWZ",
    enLabel: "Swaziland",
    krLabel: "스와질란드",
  },
  {
    iso: "TCA",
    enLabel: "Turks and Caicos Islands",
    krLabel: "터크 스 케이 커스 제도",
  },
  {
    iso: "TCD",
    enLabel: "Chad",
    krLabel: "차드",
  },
  {
    iso: "ATF",
    enLabel: "French Southern Territories",
    krLabel: "프랑스령 남방 및 남극",
  },
  {
    iso: "TGO",
    enLabel: "Togo",
    krLabel: "토고",
  },
  {
    iso: "THA",
    enLabel: "Thailand",
    krLabel: "태국",
  },
  {
    iso: "TJK",
    enLabel: "Tajikistan",
    krLabel: "타지키스탄",
  },
  {
    iso: "TKL",
    enLabel: "Tokelau",
    krLabel: "토켈라우",
  },
  {
    iso: "TLS",
    enLabel: "East Timor",
    krLabel: "동 티모르",
  },
  {
    iso: "TKM",
    enLabel: "Turkmenistan",
    krLabel: "투르크메니스탄",
  },
  {
    iso: "TUN",
    enLabel: "Tunisia",
    krLabel: "튀니지",
  },
  {
    iso: "TON",
    enLabel: "Tonga",
    krLabel: "통가",
  },
  {
    iso: "TUR",
    enLabel: "Turkey",
    krLabel: "터키",
  },
  {
    iso: "TTO",
    enLabel: "Trinidad and Tobago",
    krLabel: "트리니다드 토바고",
  },
  {
    iso: "TUV",
    enLabel: "Tuvalu",
    krLabel: "투발루",
  },
  {
    iso: "TWN",
    enLabel: "Taiwan Region",
    krLabel: "대만",
  },
  {
    iso: "TZA",
    enLabel: "Tanzania",
    krLabel: "탄자니아 연합 공화국",
  },
  {
    iso: "UKR",
    enLabel: "Ukraine",
    krLabel: "우크라이나",
  },
  {
    iso: "UGA",
    enLabel: "Uganda",
    krLabel: "우간다",
  },
  {
    iso: "UMI",
    enLabel: "United States Minor Outlying Islands",
    krLabel: "미국령 군소 제도",
  },
  {
    iso: "USA",
    enLabel: "United States",
    krLabel: "아메리카 합중국",
  },
  {
    iso: "URY",
    enLabel: "Uruguay",
    krLabel: "우루과이",
  },
  {
    iso: "UZB",
    enLabel: "Uzbekistan",
    krLabel: "우즈베키스탄",
  },
  {
    iso: "VAT",
    enLabel: "Vatican",
    krLabel: "바티칸 시국",
  },
  {
    iso: "VCT",
    enLabel: "Saint Vincent and the Grenadines",
    krLabel: "세인트 빈센트 그레나딘",
  },
  {
    iso: "VEN",
    enLabel: "Venezuela",
    krLabel: "베네수엘라, 볼리바르 공화국",
  },
  {
    iso: "VGB",
    enLabel: "Virgin Islands, British",
    krLabel: "영국령 버진 아일랜드",
  },
  {
    iso: "VIR",
    enLabel: "Virgin Islands, U.S.",
    krLabel: "미국령 버진 아일랜드",
  },
  {
    iso: "VNM",
    enLabel: "Vietnam",
    krLabel: "베트남",
  },
  {
    iso: "VUT",
    enLabel: "Vanuatu",
    krLabel: "바누아투",
  },
  {
    iso: "WLF",
    enLabel: "Wallis and Futuna",
    krLabel: "월리스 푸투나",
  },
  {
    iso: "WSM",
    enLabel: "Samoa",
    krLabel: "사모아",
  },
  {
    iso: "YEM",
    enLabel: "Yemen",
    krLabel: "예멘 아랍 공화국",
  },
  {
    iso: "MYT",
    enLabel: "Mayotte",
    krLabel: "마요트",
  },
  {
    iso: "ZAF",
    enLabel: "South Africa",
    krLabel: "남아프리카",
  },
  {
    iso: "ZMB",
    enLabel: "Zambia",
    krLabel: "잠비아",
  },
  {
    iso: "ZWE",
    enLabel: "Zimbabwe",
    krLabel: "짐바브웨",
  },
];

export default CountryNameMapping;
