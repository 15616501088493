import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import "./Kiosk.scss";
import QRCode from "qrcode.react";
import { StoreContext } from "../../context/StoreContext/StoreContext";
import jwt from "jsonwebtoken";
import logo from "../../assets/chanel.svg";
import cron from "cron";
import { useTranslation } from "react-i18next";
import toHtml from "../../utils/toHtml";
import { getMerchantId } from "../../api/Kiosk.service";

const Kiosk: React.FC = () => {  
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { setStoreId, translationsParsed, setTokenIsValid } = useContext(StoreContext);
  const [token, setToken] = useState("");
  const [secret, setSecret] = useState(process.env.REACT_APP_TOKEN_SECRET || "");
  const [domain, setDomain] = useState( process.env.REACT_APP_BASE_DOMAIN_URL || "");
  const [kioskStoreId, ] = useState(new URLSearchParams(history.location.search).get("id"));

  const generateNewToken = useCallback(async () => {
    const newToken = jwt.sign(
      {
        exp: Math.floor(Date.now() / 1000) + (60 * 2), // Expire in 2 minutes
        storeId: kioskStoreId,
      },
      secret
    );
    setToken(newToken);
  }, [secret, kioskStoreId]);

  // Will generate a new QR code every 1 minute
  const [generateTokenJob] = useState(new cron.CronJob("*/10 * * * * *", async () => {
    generateNewToken();
  }));

  useEffect(() => {
    setSecret(process.env.REACT_APP_TOKEN_SECRET || "");
    setDomain(process.env.REACT_APP_BASE_DOMAIN_URL || "")
    setTokenIsValid(true);
  }, [setTokenIsValid])

  useEffect(() => {
    generateTokenJob.start();
  }, [generateTokenJob]);

  useEffect(() => {
    const storeId = new URLSearchParams(history.location.search).get("id");
    setStoreId(storeId || "")
  }, [history.location.search, setStoreId, setTokenIsValid]);

  useEffect(() => {
      generateNewToken();
  }, [generateNewToken, kioskStoreId]);

  const merchantId = getMerchantId();

  return token && translationsParsed ? (
    <div className="qr-code-container">
      <div>
        <img src={logo} alt="logo" /> 
        {
          i18n.exists && i18n.exists("QR_INSTRUCTION") && 
          <> 
            <h5>{toHtml(t("QR_INSTRUCTION"))}</h5>
          </>
        }
      </div>
      <QRCode data-testid="qr-code" size={250} value={`${domain}/?token=${token}&merchantId=${merchantId}`} />
    </div>
  ) : (<></>);
};

export default Kiosk;
