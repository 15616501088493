import "./Header.scss";
import logo from "../../assets/chanel.svg";
import whiteLogo from "../../assets/chanel-white.png";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useHistory, useLocation } from "react-router";
import { useState } from "react";
import LanguageSelectionDropdown from "../LanguageSelectionDropdown/LanguageSelectionDropdown";

interface Props {
  blackPage: boolean;
}

const Header: React.FC<Props> = ({blackPage}) => {
  const location = useLocation();
  const history = useHistory();
  const [pagesWithArrow] = useState( ["/details"]);

  return location.pathname !== "/kiosk" ? (
    <div className={`header-container ${!pagesWithArrow.includes(location.pathname) ? "centered" : ""}`}>
      
      <div className="header-action-container">
        { pagesWithArrow.includes(location.pathname) && 
          <ArrowBackIosNewIcon 
          onClick={() => {
            history.push('/');
          }}
          data-testid="back-chevron"
          />
        }

        { location.pathname === "/" && 
          <LanguageSelectionDropdown />
        }
      </div>
      
      <div className="logo-container">
        { blackPage ? 
          <img src={whiteLogo} alt="logo" className="white-logo"/> :
          <img src={logo} alt="logo" /> 
        }
      </div> 
      <span></span>
    </div> 
  ) : (<></>)
};

export default Header;
