import { MenuItem, Select, FormControl } from "@mui/material";
import { useContext } from "react";
import "./LanguageSelectionDropdown.scss";
import { StoreContext } from "../../context/StoreContext/StoreContext";

const LanguageSelectionDropdown: React.FC = () => {
  const {
    translationsParsed,
    languageSelection,
    setLanguageSelection,
    languages,
  } = useContext(StoreContext);

  return translationsParsed && languages.length > 0 && languageSelection ? (
    <div className="language-dropdown-container">
      <FormControl className="language-dropdown" data-testid="language-select">
        <Select
          disableUnderline
          variant="standard"
          float-label="never"
          value={languageSelection}
          onChange={(e) => setLanguageSelection(e.target.value)}
          color="secondary"
          className="select-control"
          sx={{
            "& .MuiSvgIcon-root": { color: "black" },
          }}
        >
          {languages.map((lang, index) => (
            <MenuItem
              key={lang.countryCallingCode + index}
              value={lang.languageIsoCode}
              data-testid="language-option"
            >
              {lang.localisedName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  ) : (
    <></>
  );
};

export default LanguageSelectionDropdown;
