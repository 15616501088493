import { useTranslation } from "react-i18next";
import "./Closed.scss";

const Closed: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="closed-page-container">
      <p className="closed-page-message">{t("CLOSED_MESSAGE")}</p>
    </div>
  );
};

export default Closed;
