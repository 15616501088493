import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import jwt from "jsonwebtoken";
import { KioskService } from "../../api/Kiosk.service";
import { QueueDataContext } from "../QueueDataContext/QueueDataContext";
import { parseTranslations } from "../../utils/translationParser";
import { ApiTranslations } from "../../models/ApiTranslations";
import {
  getSessionStorageItem,
  setSessionStorageItem,
} from "../../utils/storageHelper";
import { useTranslation } from "react-i18next";
import { TimeService } from "../../api/Time.service";
import { Question } from "../../models/Question";

interface Props {
  children: React.ReactNode;
  loaded?: boolean;
}

type Language = {
  countryName: string;
  countryIsoCode: string;
  countryCallingCode: string;
  languageName: string;
  languageIsoCode: string;
  isEnabled: boolean;
  localisedName: string;
  userLocalisedName: string;
};

interface StoreContextInterface {
  storeId: string | null;
  tokenIsValid: boolean | undefined;
  storeDataLoaded: boolean;
  products: any[];
  translationsParsed: boolean;
  setStoreId: (str: string | null) => void;
  setTokenIsValid: (bool: boolean) => void;
  validateToken: (token: string | null) => void;
  languageSelection: string;
  setLanguageSelection: (str: string) => void;
  timeVerified: boolean;
  countryCode: string | null;
  languages: Language[];
}

export const StoreContext = createContext<StoreContextInterface>(
  {} as StoreContextInterface
);

const StoreContextProvider: React.FC<Props> = ({ children }) => {
  const secret = process.env.REACT_APP_TOKEN_SECRET || "";
  const history = useHistory();
  const { i18n } = useTranslation();

  const { setQueueId, setVenueKioskId, service, setQuestions } =
    useContext(QueueDataContext);

  const [storeId, setStoreId] = useState<string | null>(
    getSessionStorageItem("storeId")
  );
  const [kioskIdentifier, setKioskIdentifier] = useState<string | null>("");
  const [tokenIsValid, setTokenIsValid] = useState<boolean>();
  const [storeSettings, setStoreSettings] = useState<any>();
  const [dataResponse, setDataResponse] = useState<any>();
  const [storeDataLoaded, setStoreDataLoaded] = useState<boolean>(false);
  const [storeClosed, setStoreClosed] = useState<boolean>(false);
  const [products, setProducts] = useState([]);
  const [textTranslations, setTextTranslations] = useState<ApiTranslations>();
  const [translationsParsed, setTranslationsParsed] = useState<boolean>(false);
  const [timeVerified, setTimeVerified] = useState<boolean>(false);

  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [languages, setLanguages] = useState<Language[]>([]);
  const [languageSelection, setLanguageSelection] = useState<string>(
    getSessionStorageItem("language")
  );
  const [countryCode, setCountryCode] = useState<string | null>("");

  const timeOut = Number(process.env.REACT_APP_TIMEOUT) || 10;

  const setTimeOut = useCallback(() => {
    const timeOutToken = jwt.sign(
      {
        exp: Math.floor(Date.now() / 1000) + (60 * timeOut),
      },
      "b0d34db6-b0e1-4249-8b96-2d7ab518bfca"
    );
    setSessionStorageItem(timeOutToken, "timeOut");
  }, [timeOut]);

  const verifyTime = useCallback(async () => {
    try {
      const timeResponse = await TimeService.verifyTime({
        clientTime: Math.floor(Date.now() / 1000),
      });
      if (timeResponse.successToken) {
        jwt.verify(
          timeResponse.successToken,
          "4ec22fda-ab06-4159-b52e-2017ba4c9506",
          (err: any, decoded: any) => {
            if (err) {
              history.push("/error?code=008");
            } else {
              setTimeVerified(true);
            }
          }
        );
      } else {
        history.push("/error?code=009");
      }
    } catch (err) {
      process.env.NODE_ENV === "development"
        ? setTimeVerified(true)
        : history.push("/error?code=010");
    }
  }, [history]);

  const getKioskIdentifier = useCallback(
    async (storeId: string) => {
      try {
        const identifierResponse = await KioskService.getIdentifier(storeId);
        if (identifierResponse.id) {
          setKioskIdentifier(identifierResponse.id);
        }
      } catch (err) {
        history.push("/error?code=003");
      }
    },
    [history]
  );

  const getKioskData = useCallback(
    async (kioskId: string) => {
      try {
        const dataResponse = await KioskService.getData(kioskId);
        if (dataResponse && dataResponse[0]) {
          setDataResponse(dataResponse[0]);
          setQueueId(dataResponse[0].queueId);
          setStoreClosed(!dataResponse[0].storeOpen);
        }
      } catch (err) {
        history.push("/error?code=004");
      }
    },
    [history, setQueueId]
  );

  const getQuestions = useCallback(
    async (kioskId: string) => {
      try {
        const questionsResponse = await KioskService.getQuestions(
          service,
          kioskId
        );
        if (questionsResponse && questionsResponse[0]) {
          const questionsStringArray: Question[] = [];
          questionsResponse.forEach(
            (question: { questionInfoText: string; id: number }) => {
              if (question.questionInfoText && question.id) {
                questionsStringArray.push({
                  questionId: question.id,
                  questionKey: question.questionInfoText,
                });
              }
            }
          );
          setQuestions(questionsStringArray);
          setSessionStorageItem(questionsStringArray, "questions");
        }
      } catch (err) {
        history.push("/error?code=013");
      }
    },
    [history, service, setQuestions]
  );

  const getKioskSettings = useCallback(
    async (kioskId: string) => {
      try {
        const settingsResponse = await KioskService.getSettings(kioskId);
        if (settingsResponse) {
          setStoreSettings(settingsResponse);
          setProducts(settingsResponse.products);

          setTextTranslations(settingsResponse.template.customTextTranslations);
          setVenueKioskId(settingsResponse.id);
          setCountryCode(settingsResponse.venue.defaultCountryCode);
          setLanguages([
            settingsResponse.template.languages.mainLanguage,
            ...settingsResponse.template.languages.otherLanguages.filter((otherLanguage: Language) => settingsResponse.template.languages.mainLanguage.languageIsoCode !== otherLanguage.languageIsoCode
            ),
          ]);
        }
      } catch (err) {
        history.push("/error?code=005");
      }
    },
    [history, setVenueKioskId]
  );

  useEffect(() => {
    if (service && kioskIdentifier) {
      getQuestions(kioskIdentifier);
    }
  }, [getQuestions, kioskIdentifier, service]);

  useEffect(() => {
    if (tokenIsValid === false) {
      history.push("/error?code=006");
    } else if (tokenIsValid && storeId) {
      setSessionStorageItem(storeId, "storeId");
      getKioskIdentifier(storeId);
      verifyTime();
    }
  }, [getKioskIdentifier, history, storeId, tokenIsValid, verifyTime]);

  useEffect(() => {
    if (kioskIdentifier) {
      getKioskSettings(kioskIdentifier);
      getKioskData(kioskIdentifier);
    }
  }, [getKioskData, getKioskSettings, kioskIdentifier]);

  useEffect(() => {
    if (storeSettings && dataResponse) {
      setStoreDataLoaded(true);
      setTimeOut();
    }
  }, [storeSettings, dataResponse, setTimeOut]);

  useEffect(() => {
    if (storeClosed && translationsParsed) {
      history.push("/closed");
    }
  }, [translationsParsed, history, storeClosed]);

  useEffect(() => {
    if (textTranslations) {
      parseTranslations(textTranslations);
      setTranslationsParsed(true);
      forceUpdate();
    }
  }, [textTranslations]);

  useEffect(() => {
    if (languages.length > 0 && !getSessionStorageItem("language")) {
      setLanguageSelection(languages[0].languageIsoCode);
      setSessionStorageItem(languages[0].languageIsoCode, "language");
    }
  }, [i18n, languages]);

  useEffect(() => {
    i18n.changeLanguage(languageSelection);
    setSessionStorageItem(languageSelection, "language");
  }, [i18n, languageSelection]);

  const validateToken = (token: string | null) => {
    let valid = true;
    if (!token) {
      valid = false;
    } else {
      jwt.verify(token, secret, (err, decoded) => {
        if (err) {
          valid = false;
        }
        if (!decoded || !decoded.exp || !decoded.storeId) {
          valid = false;
        } else {
          setStoreId(decoded.storeId);
        }
      });
    }
    setTokenIsValid(valid);
  };

  return (
    <StoreContext.Provider
      value={{
        storeId,
        tokenIsValid,
        storeDataLoaded,
        products,
        setStoreId,
        setTokenIsValid,
        validateToken,
        translationsParsed,
        languageSelection,
        setLanguageSelection,
        timeVerified,
        countryCode,
        languages,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

export default StoreContextProvider;
