import './Loader.scss'
import loader from '../../assets/loader.gif'

const Loader : React.FC = () => {
    return (
        <div className="loader-container">
            <img src={loader} alt="loading..." />
        </div>
    );
}
  
export default Loader;
