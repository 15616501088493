import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./i18n";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider, CssBaseline } from "@mui/material";
import QueueDataContextProvider from "./context/QueueDataContext/QueueDataContext";
import theme from "./constants/theme/theme";
import StoreContextProvider from "./context/StoreContext/StoreContext";
import Loader from "./components/Loader/Loader";
import dotenv from "dotenv";
import RouterContextProvider from "./context/RouterContext/RouterContext";
import KakaoAuthContextProvider from "./context/KakaoAuthContext/KakaoAuthContext";
import ValidationContextProvider from "./context/ValidationContext/ValidationContext";
import NiceIdContextProvider from "./context/NiceIdContext/NiceIdContext";

dotenv.config();

ReactDOM.render(
  <React.StrictMode>
    <CssBaseline />
    <Suspense fallback={<Loader />}>
      <BrowserRouter>
        <QueueDataContextProvider>
          <NiceIdContextProvider>
            <KakaoAuthContextProvider>
              <StoreContextProvider>
                <ValidationContextProvider>
                  <RouterContextProvider>
                    <ThemeProvider theme={theme}>
                      <App />
                    </ThemeProvider>
                  </RouterContextProvider>
                </ValidationContextProvider>
              </StoreContextProvider>
            </KakaoAuthContextProvider>
          </NiceIdContextProvider>
        </QueueDataContextProvider>
      </BrowserRouter>
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
