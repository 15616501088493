export class BaseService {
  static async getRequest(url: string) {
    try {
      const res = await fetch(url);
      return res.json();
    } catch (err) {
        throw new Error("get request failed")
    }
  }

  static async postRequest(url: string, body: any) {
    try {
      const res = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      return res.json();
    } catch (err) {
        throw new Error("post request failed")
    }
  }
}
