import "./Checkbox.scss";

interface Props {
  checked: boolean;
  setChecked: (bool: boolean) => void;
  error?: boolean;
}

const Checkbox: React.FC<Props> = ({ checked, setChecked, error }) => {
  return (
    <label className="container">
      <input
        data-testid="checkbox"
        type="checkbox"
        checked={checked}
        onChange={(e) => {
          setChecked(!checked);
        }}
      />
      <span className={`checkmark ${error && !checked ? "error" : ""}`}></span>
    </label>
  );
};

export default Checkbox;
