import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import jwt from "jsonwebtoken";
import { NiceIdService } from "../../api/NiceId.service";
import { useHistory } from "react-router";
import { getSessionStorageItem, setSessionStorageItem } from "../../utils/storageHelper";
import { QueueDataContext } from "../QueueDataContext/QueueDataContext";
import { sanitisePhoneNumber } from "../../utils/misc";

interface Props {
  children: React.ReactNode;
}

interface NiceIdContextInterface {
  niceIdVerified: boolean;
  validateNiceIdToken: (token: string | null) => boolean;
  niceIdEncodedData: string;
}

export const NiceIdContext = createContext<NiceIdContextInterface>(
  {} as NiceIdContextInterface
);

const NiceIdContextProvider: React.FC<Props> = ({ children }) => {
  const history = useHistory();
  const [niceIdVerified, setNiceIdVerified] = useState(false);
  const [niceIdEncodedData, setNiceIdEncodedData] = useState(getSessionStorageItem("niceIdEncoded") || "");
  const { setTelephone, setAuthenticated, setCountryCode, setFirstName, setLastName, setDobDay, setDobMonth, setDobYear } = useContext(QueueDataContext);

  const baseUrl = process.env.REACT_APP_BASE_DOMAIN_URL || "";
  const tokenSecret = process.env.REACT_APP_NICE_ID_TOKEN_SECRET || "";

  const getEncodedData = useCallback(async () => {
    try {
      const successUrl = `${baseUrl}/.netlify/functions/niceIdSuccess`;
      const errorUrl = `${baseUrl}/.netlify/functions/niceIdFail`;
      const response = await NiceIdService.getEncodedData(successUrl, errorUrl);
      if (response.data) {
        setNiceIdEncodedData(response.data);
        setSessionStorageItem(response.data, "niceIdEncoded")
      }
    } catch (err) {
      history.push("/error?code=012");
    }
  }, [baseUrl, history]);

  useEffect(() => {
    getEncodedData();
  }, [getEncodedData])

  const splitName = (name: string) => {
    const nameSplit = name.split("+");

    if (nameSplit[1]) { // English name
      return { lastName: nameSplit[0], firstName: nameSplit[1] }
    } else { // Korean name
      return { lastName: name.substring(0, 1), firstName: name.substring(1) }
    }
  }

  const splitDob = (dob: string) => {
    return {year: dob.substring(0, 4), month: dob.substring(4, 6), day: dob.substring(6)}
  }

  const validateNiceIdToken = (token: string | null): boolean => {
    let valid = true;
    if (!token) {
      valid = false;
    } else {
      jwt.verify(
        token,
        tokenSecret,
        (err, decoded) => {
          if (err) {
            valid = false;
          }
          if (!decoded || !decoded.exp || !decoded.data) {
            valid = false;
          } else {
            if (!decoded.data.failed) {
              console.log(decoded.data);
              setTelephone(sanitisePhoneNumber(decoded.data.mobileno)); 
              setCountryCode("KR");
              const splitNames = splitName(decoded.data.utf8Name);
              setFirstName(splitNames.firstName);
              setLastName(splitNames.lastName);
              const splitDateOfBirth = splitDob(decoded.data.birthdate);
              setDobDay(splitDateOfBirth.day);
              setDobMonth(splitDateOfBirth.month);
              setDobYear(splitDateOfBirth.year);
              setAuthenticated(true);
            }
          }
        }
      );
    }
    setNiceIdVerified(valid);
    return valid;
  };

  return (
    <NiceIdContext.Provider
      value={{
        niceIdVerified,
        validateNiceIdToken,
        niceIdEncodedData,
      }}
    >
      {children}
    </NiceIdContext.Provider>
  );
};

export default NiceIdContextProvider;
