import React, { useState } from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import Home from "./pages/Home/Home";
import { Container } from "@mui/material";
import Error from "./pages/Error/Error";
import Header from "./components/Header/Header";
import Closed from "./pages/Closed/Closed";
import Details from "./pages/Details/Details";
import Kiosk from "./pages/Kiosk/Kiosk";

const App: React.FC = () => {
  const [blackPages] = useState(["/closed", "/error"]);
  const location = useLocation();
  const blackPage = blackPages.includes(location.pathname);

  return (
    <Container
      maxWidth="lg"
      className={` ${blackPage ? "black-container" : ""}`}
    >
      <Header blackPage={blackPage} />
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/error" exact component={Error} />
        <Route path="/closed" exact component={Closed} />
        <Route path="/details" exact component={Details} />
        <Route path="/kiosk" exact component={Kiosk} />
        <Redirect to="/error" />
      </Switch>
    </Container>
  );
};

export default App;
