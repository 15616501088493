import { BaseService } from "./Base.service";

export class NiceIdService extends BaseService {
  static baseURL = process.env.REACT_APP_BASE_DOMAIN_URL + "/.netlify/functions";

  static getEncodedData(returnUrl: string, errorUrl: string): Promise<any> {
    const url = `${this.baseURL}/getEncodedData?returnUrl=${returnUrl}&errorUrl=${errorUrl}`;
    return this.getRequest(url);
  }
}
