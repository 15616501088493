import { IdentifierResponse } from "../models/IdentifierResponse";
import { BaseService } from "./Base.service";

export function getMerchantId() {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('merchantId') || process.env.REACT_APP_MERCHANT_ID;
}

export class KioskService extends BaseService {
  static baseURL = process.env.REACT_APP_KIOSK_API_BASE_URL;


  static merchantId = getMerchantId();

  static getIdentifier(storeId: string): Promise<IdentifierResponse> {
    const url = `${this.baseURL}/api/kiosk/identifier?merchantId=${this.merchantId}&kioskType=chanel-qr&serial=web&storeId=${storeId}`;
    return this.getRequest(url);
  }

  static getSettings(identifier: string): Promise<any> {
    const url = `${this.baseURL}/api/kiosk/settings/${identifier}?serial=web`;
    return this.getRequest(url);
  }

  static getData(identifier: string): Promise<any> {
    const url = `${this.baseURL}/api/kiosk/data/${identifier}?serial=web`;
    return this.getRequest(url);
  }

  static getQuestions(productId: string, identifier: string): Promise<any> {
    const url = `${this.baseURL}/api/kiosks/${identifier}/questions?productId=${productId}&serial=web`;
    return this.getRequest(url);
  }

  static addCustomerToQueue(body: any): Promise<any> {
    const url = `${this.baseURL}/api/kiosk/add-customer-to-queue`;
    return this.postRequest(url, body);
  }
}
