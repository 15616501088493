import React, { createContext, useContext, useState } from 'react';
import jwt from 'jsonwebtoken';
import { QueueDataContext } from '../QueueDataContext/QueueDataContext';
import { getSessionStorageItem, setSessionStorageItem } from '../../utils/storageHelper';

interface Props {
  children: React.ReactNode;
}

interface KakaoAuthContextInterface {
  loggedInWithKakao: boolean;
  validateKakaoToken: (token: string | null) => boolean;
  setLoggedInWithKakao: (boolean: boolean) => void;
}

export const KakaoAuthContext = createContext<KakaoAuthContextInterface>({} as KakaoAuthContextInterface);

const KakaoAuthContextProvider: React.FC<Props> = ({ children }) => {
  const [loggedInWithKakao, setLoggedInWithKakao] = useState(Boolean(getSessionStorageItem("loggedInWithKakao")) || false);
  const { setDataFromKakao } = useContext(QueueDataContext);
  const tokenSecret = process.env.REACT_APP_KAKAO_TOKEN_SECRET || "";

  const validateKakaoToken = (token: string | null): boolean => {
    let valid = true;
    if (!token) { 
      valid = false;
    } else {
      jwt.verify(token, tokenSecret, (err, decoded) => {
        if (err) {
          valid = false;
        }
        if (!decoded || !decoded.exp || !decoded.data) {
          valid = false;
        } else {
          setDataFromKakao(decoded.data);
        }
      });
    }
    setLoggedInWithKakao(valid);
    setSessionStorageItem(valid.toString(), "loggedInWithKakao");
    return valid;
  }

  return (
    <KakaoAuthContext.Provider value={{
      loggedInWithKakao,
      validateKakaoToken,
      setLoggedInWithKakao
    }}>
      {children}
    </KakaoAuthContext.Provider>
  );
};

export default KakaoAuthContextProvider;
