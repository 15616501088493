import { BaseService } from "./Base.service";

export class TimeService extends BaseService {
  static baseURL = process.env.REACT_APP_BASE_DOMAIN_URL;

  static verifyTime(body: any): Promise<any> {
    const url = `${this.baseURL}/.netlify/functions/verifyTime`
    return this.postRequest(url, body);
  }
}
