import "./Error.scss";


const FALLBACK_LNG = "en";

const getLanguageMatch = (availableLanguages: string[], languages: readonly string[]) => {
  for (var x = 0; x < languages.length; x++) {
    for (var y = 0; y < availableLanguages.length; y++) {
      if (
        languages[x]
          .toLowerCase()
          .startsWith(availableLanguages[y].toLowerCase())
      ) {
        return availableLanguages[y];
      }
    }
  }
  return undefined;
};

const getDefaultLanguage = (availableLanguages: string[]) => {
  let defaultLanguage = undefined;

  if (availableLanguages && !defaultLanguage) {
    const browserLanguages = window.navigator.languages;

    if (browserLanguages) {
      defaultLanguage = getLanguageMatch(availableLanguages, browserLanguages);
    }

    if (!defaultLanguage && !browserLanguages) {
      const browserDefaultLanguage = window.navigator.language;
      if (browserDefaultLanguage) {
        defaultLanguage = getLanguageMatch(availableLanguages, [
          browserDefaultLanguage
        ]);
      }
    }
  }

  return defaultLanguage || FALLBACK_LNG;
};

const Error: React.FC = () => {

  const preferredLanguage = getDefaultLanguage([
    "ko",
    "th",
    "zh",
    "en"
  ])

  return (
    <div className="error-page-container">
      <div className="error-page-messages">
        {preferredLanguage === "ko" &&
          <div>
            <p className="error-page-message">죄송합니다.</p>
            <p className="error-page-message">
              일시적 오류로 인해 부티크 어드바이저에게 도움을 요청해 주세요.
            </p>
          </div>
        }

        {preferredLanguage === "th" &&
          <div >
            <p className="error-page-message">ขออภัยในความไม่สะดวก</p>
            <p className="error-page-message">
              เนื่องจากเกิดความผิดพลาดขึ้นชั่วคราว กรุณาสอบถามขอความช่วยเหลือได้จากทางเจ้าหน้าที่ของบูติค
            </p>
          </div>
        }

        {preferredLanguage === "zh" &&
          <>
            <div>
              <p className="error-page-message">由于技术问题，请寻求精品顾问的帮助。</p>
              <p className="error-page-message">
                不便之处敬请谅解。
              </p>
            </div>

          <div className="margin-top">
            <p className="error-page-message">由於技術問題，請尋求精品顧問的幫助。</p>
            <p className="error-page-message">
              不便之處敬請諒解。
            </p>
          </div>
          </>
        }

        <div className="margin-top">
          <p className="error-page-message">
            Due to technical error, please seek assistance from boutique advisors.
          </p>
          <p className="error-page-message">
            Sorry for the inconvenience.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Error;
