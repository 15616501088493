import "./Home.scss";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { StoreContext } from "../../context/StoreContext/StoreContext";
import Loader from "../../components/Loader/Loader";
import { Button, FormControl, MenuItem, Select } from "@mui/material";
import { QueueDataContext } from "../../context/QueueDataContext/QueueDataContext";
import CheckboxAgreement from "../../components/CheckboxAgreement/CheckboxAgreement";
import { getTranslatedName } from "../../utils/translationParser";
import { getSessionStorageItem } from "../../utils/storageHelper";
import { KakaoAuthContext } from "../../context/KakaoAuthContext/KakaoAuthContext";
import toHtml from "../../utils/toHtml";
// import jwt from "jsonwebtoken";

const Home: React.FC = () => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const {
    validateToken,
    tokenIsValid,
    storeDataLoaded,
    translationsParsed,
    timeVerified,
  } = useContext(StoreContext);

  const { products } = useContext(StoreContext);
  const { service, setService, authenticated } = useContext(QueueDataContext);
  const { loggedInWithKakao, setLoggedInWithKakao } =
    useContext(KakaoAuthContext);

  const [provideInfoAgreed, setProvideInfoAgreed] = useState(
    loggedInWithKakao || false
  );
  const kakaoUrl = process.env.REACT_APP_KAKAO_SERVICE_URL || "";

  useEffect(() => {
    const token = new URLSearchParams(history.location.search).get("token");

    // This is here for dev purposes, will be removed at later date
    // const token = jwt.sign(
    //   {
    //     exp: Math.floor(Date.now() / 1000) + 90, //Expire now + 1.5 minutes
    //     storeId: "dev",
    //   },
    //   "3774385d-a31b-4119-8079-a45e06ee70d4"
    // );
    if (tokenIsValid === undefined) {
      validateToken(token);
    }
  }, [history.location.search, tokenIsValid, validateToken]);

  useEffect(() => {
    if (products && products[0] && !getSessionStorageItem("productId")) {
      setService(products[0].id);
    }
  }, [products, setService]);

  const handleNextClick = () => {
    if (provideInfoAgreed && !loggedInWithKakao && !authenticated) {
      window.location.href = kakaoUrl;
    } else {
      if (loggedInWithKakao && !provideInfoAgreed) {
        setLoggedInWithKakao(false);
      }
      history.push("/details");
    }
  };

  return tokenIsValid &&
    storeDataLoaded &&
    translationsParsed &&
    timeVerified ? (
    <div className="home-wrapper">
      <div className="heading-container">
        <h5 className="center header-line-1">{t("HOME_TITLE_LINE_1")}</h5>
        <h5 className="center">{t("HOME_TITLE_LINE_2")}</h5>
      </div>

      <div className="product-dropdown-container">
        <FormControl
          className="product-dropdown"
          data-testid="product-select"
          sx={{
            "& .MuiInput-underline:before": { borderBottomColor: "black" },
            "& .MuiInput-underline:after": { borderBottomColor: "black" },
            "& .MuiSvgIcon-root": { color: "black" },
          }}
        >
          <Select
            variant="standard"
            float-label="never"
            value={service}
            onChange={(e) => setService(e.target.value)}
            color="secondary"
            fullWidth
            className="select-control product-select"
          >
            {products.map((product) => {
              return (
                <MenuItem
                  className="product-menu-item"
                  key={product.id}
                  value={product.id}
                  data-testid="korean-option"
                >
                  {getTranslatedName(product.nameTranslations, i18n.language)}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </div>

        {i18n.exists("HOME_DISCLAIMER") &&
          <div className="center disclaimer-container">
            <h5 className="bold">{toHtml(t("HOME_DISCLAIMER"))}</h5>
          </div>
        }

      {i18n.exists("HOME_CHECKBOX_AGREEMENT") && (
        <div className="agreement-wrapper">
          <CheckboxAgreement
            headingText={t("HOME_CHECKBOX_AGREEMENT")}
            checked={provideInfoAgreed}
            setChecked={setProvideInfoAgreed}
          />
        </div>
      )}
      <div className="bottom-button-container">
        <Button
          variant={"contained"}
          color={"secondary"}
          fullWidth
          className={"bottom-button"}
          onClick={() => handleNextClick()}
          data-testid="bottom-button"
        >
          {t("HOME_BUTTON_TEXT")}
        </Button>
      </div>
    </div>
  ) : (
    <Loader />
  );
};

export default Home;
