import { useTranslation } from "react-i18next";
import "./Details.scss";
import {
  TextField,
  Button,
  Select,
  FormControl,
  InputAdornment,
  MenuItem,
} from "@mui/material";
import { useContext, useEffect } from "react";
import { QueueDataContext } from "../../context/QueueDataContext/QueueDataContext";
import DateField from "../../components/DateField/DateField";
import CheckboxAgreement from "../../components/CheckboxAgreement/CheckboxAgreement";
import { ValidationContext } from "../../context/ValidationContext/ValidationContext";
import { KakaoAuthContext } from "../../context/KakaoAuthContext/KakaoAuthContext";
import { StoreContext } from "../../context/StoreContext/StoreContext";
import Loader from "../../components/Loader/Loader";
import { getSessionStorageItem, setSessionStorageItem } from "../../utils/storageHelper";
import jwt from "jsonwebtoken";
import { useHistory } from "react-router";
import CountryCodeMapping from "../../constants/mappings/countryCodeMapping";
import NiceIdButton from "../../components/NiceIdButton/NiceIdButton";
import CountryNameMapping, {
  CountryNameMappingPreferred,
} from "../../constants/mappings/countryNameMapping";
import toHtml from "../../utils/toHtml";

const Details: React.FC = () => {
  const { t, i18n } = useTranslation();

  const history = useHistory();

  const { loggedInWithKakao } = useContext(KakaoAuthContext);
  const { translationsParsed, countryCode: venueCountryCode } = useContext(StoreContext);
  const {
    addCustomerToQueue,
    isAddCustomerToQueueLoading,
    setIsAddCustomerToQueueLoading,
  } = useContext(QueueDataContext);

  const {
    title,
    setTitle,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    telephone,
    setTelephone,
    emailAddress,
    setEmailAddress,
    countryOfResidence,
    setCountryOfResidence,
    ageDeclaration,
    setAgeDeclaration,
    consent,
    setConsent,
    consentContact,
    setConsentContact,
    consentMarketing,
    setConsentMarketing,
    dobDay,
    setDobDay,
    dobMonth,
    setDobMonth,
    dobYear,
    setDobYear,
    authenticated,
    countryCode,
    setCountryCode,
    clearData
  } = useContext(QueueDataContext);

  useEffect(() => {
    if (venueCountryCode) {
      setCountryCode(venueCountryCode.toUpperCase());
    }
  }, [venueCountryCode, setCountryCode])

  const {
    titleValid,
    firstNameValid,
    lastNameValid,
    telephoneValid,
    emailValid,
    countryOfResidenceValid,
    dobValid,
    ageDeclarationValid,
    consentValid,
    // consentContactValid,
    // consentMarketingValid,
    dobAgeValid,
    telephoneAuthenticated,
    telephoneTouched,
    setTelephoneTouched,
    validateTitle,
    validateDob,
    validateTelephone,
    validateLastName,
    validateFirstName,
    validateEmail,
    validateCountryOfResidence,
    validateAll,
  } = useContext(ValidationContext);

  useEffect(() => {
    if (loggedInWithKakao && authenticated) {
      validateTelephone();
    }
  }, [authenticated, loggedInWithKakao, validateTelephone]);

  const whitespaceCleanUp = (str: string): string =>
    str.replace(/^\s+|\s+$|\s+(?=\s)/g, "");

  const cleanUpFirstName = () => {
    if (firstName) {
      const cleanFirstName = whitespaceCleanUp(firstName);
      if (firstName !== cleanFirstName) {
        setFirstName(cleanFirstName);
      }
    }
  };

  const cleanUpLastName = () => {
    if (lastName) {
      const cleanLastName = whitespaceCleanUp(lastName);
      if (lastName !== cleanLastName) {
        setLastName(cleanLastName);
      }
    }
  };

  const cleanUpDayDOB = () => {
    if (dobDay && dobDay.length < 2) {
      setDobDay(dobDay.padStart(2, "0"));
    }
  };

  const cleanUpMonthDOB = () => {
    if (dobMonth && dobMonth.length < 2) {
      setDobMonth(dobMonth.padStart(2, "0"));
    }
  };

  const submitForm = () => {
    setIsAddCustomerToQueueLoading(true);

    try {
      const timeOutToken = getSessionStorageItem("timeOut");

      jwt.verify(
        timeOutToken,
        "b0d34db6-b0e1-4249-8b96-2d7ab518bfca",
        (err: any) => {
          if (err) {
            history.push("/error?code=007");
            setIsAddCustomerToQueueLoading(false);
          } else if (validateAll()) {
            addCustomerToQueue();
            setSessionStorageItem(undefined, "timeOut");
            clearData();
          }
          setIsAddCustomerToQueueLoading(false);
        }
      );
    } catch {
      setIsAddCustomerToQueueLoading(false);
    }
  };

  const phoneField = (margin?: boolean) => {
    const showError =
      telephoneValid === false ||
      (loggedInWithKakao && !telephoneAuthenticated && telephoneTouched);
    
    const isOptional = i18n.exists("TELEPHONE_INPUT_OPTIONAL");
    
    return (
      <TextField
        className={` ${margin ? "phone-input-with-margin" : ""}`}
        variant="standard"
        label={t(isOptional ? "TELEPHONE_INPUT_OPTIONAL" : "TELEPHONE_INPUT")}
        fullWidth={!loggedInWithKakao}
        required={!isOptional}
        color="secondary"
        value={telephone}
        disabled={authenticated}
        onChange={(e) => {
          setTelephone(e.target.value);
        }}
        error={showError}
        helperText={
          !telephoneAuthenticated && telephoneTouched
            ? t("ERROR_AUTHENTICATE_PHONE")
            : telephoneValid === false
            ? t("ERROR_INVALID_PHONE")
            : " "
        }
        data-testid="telephone-input"
        InputLabelProps={{
          shrink: true,
        }}
        sx={{
          "& .MuiInput-underline:before": {
            borderBottomColor: showError ? "#d32f2f" : "black",
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: showError ? "#d32f2f" : "black",
          },
          "& .MuiSvgIcon-root": { color: "black" },
        }}
        InputProps={{
          inputProps: { maxLength: 13 },
          startAdornment: (
            <InputAdornment position="start">
              <FormControl
                className="country-code-select"
                data-testid="country-code-select"
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "black",
                  },
                  "& .MuiInput-underline:after": { borderBottomColor: "black" },
                }}
              >
                <Select
                  disableUnderline
                  variant="standard"
                  float-label="never"
                  value={countryCode}
                  onChange={(e) => {
                    setCountryCode(e.target.value);
                  }}
                  disabled={authenticated}
                  color="secondary"
                  className="select-control"
                >
                  {CountryCodeMapping.map((item) => {
                    return (
                      <MenuItem key={item.country} value={item.country}>
                        +{item.code}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </InputAdornment>
          ),
        }}
        onBlur={() => {
          validateTelephone();
          setTelephoneTouched(true);
        }}
      />
    );
  };

  const countryOfResidenceField = () => ((loggedInWithKakao && i18n.exists("COUNTRY_OF_RESIDENCE_INPUT_KAKAO")) || i18n.exists("COUNTRY_OF_RESIDENCE_INPUT")) &&
    (
    <TextField
      select
      fullWidth
      variant="standard"
      label={(loggedInWithKakao && i18n.exists("COUNTRY_OF_RESIDENCE_INPUT_KAKAO")) ? t("COUNTRY_OF_RESIDENCE_INPUT_KAKAO") : t("COUNTRY_OF_RESIDENCE_INPUT")}
      required={true}
      color="secondary"
      value={countryOfResidence}
      onChange={(e) => {
        setCountryOfResidence(e.target.value);
      }}
      onBlur={() => {
        validateCountryOfResidence();
      }}
      data-testid="country-of-residence-input"
      error={countryOfResidenceValid === false}
      helperText={
        countryOfResidenceValid === false
          ? t("ERROR_COUNTRY_OF_RESIDENCE_INPUT")
          : " "
      }
      InputLabelProps={{
        shrink: true,
      }}
      sx={{
        "& .MuiInput-underline:before": {
          borderBottomColor:
            countryOfResidenceValid === false ? "#d32f2f" : "black",
        },
        "& .MuiInput-underline:after": {
          borderBottomColor:
            countryOfResidenceValid === false ? "#d32f2f" : "black",
        },
        "& .MuiSvgIcon-root": { color: "black" },
      }}
    >
      {CountryNameMappingPreferred.map((country) => {
        return (
          <MenuItem key={country.iso} value={country.iso}>
            {i18n.language === "en" ? country.enLabel : country.krLabel}
          </MenuItem>
        );
      })}

      <optgroup label="" />

      {CountryNameMapping.sort((a, b) =>
        i18n.language === "kr"
          ? a.krLabel.localeCompare(b.krLabel)
          : a.enLabel.localeCompare(b.enLabel)
      ).map((country) => {
        return (
          <MenuItem key={country.iso} value={country.iso}>
            {i18n.language === "kr" ? country.krLabel : country.enLabel}
          </MenuItem>
        );
      })}
    </TextField>
    );

  const hasFirstNameLastNameTranslation = i18n.exists("LAST_NAME_INPUT") && i18n.exists("FIRST_NAME_INPUT")

  return translationsParsed ? (
    <div className="registration-page-container">
      <div className="registration-form">
        <div className="info-message mandatory-message">
          {t("MANDATORY_KEY")}
        </div>

        {i18n.exists("TITLE_LIST") && (
          <div className="title-dropdown-container">
            <TextField
              label={t("TITLE_INPUT")}
              select
              variant="standard"
              float-label="never"
              value={title}
              required
              onChange={(e) => e.target.value && setTitle(e.target.value)}
              color="secondary"
              error={titleValid === false}
              className="select-control title-select"
              helperText={titleValid === false && t("ERROR_TITLE_INPUT")}
              InputLabelProps={{
                shrink: true,
              }}
              onBlur={() => {
                validateTitle();
              }}
              disabled={authenticated}
            >
              {JSON.parse(t("TITLE_LIST")).map(
                (title: string, index: number) => {
                  return (
                    <MenuItem
                      className="title-menu-item"
                      key={`title${index}`}
                      value={title}
                      data-testid="title-option"
                    >
                      {title}
                    </MenuItem>
                  );
                }
              )}
            </TextField>
          </div>
        )}
        <div className="name-fields-container">
          <TextField
            className="half-width-field"
            variant="standard"
            label={hasFirstNameLastNameTranslation ? t("LAST_NAME_INPUT") : t("NAME_INPUT")}
            required={true}
            color="secondary"
            value={lastName}
            onChange={(e) => {
              setLastName(e.target.value);
            }}
            data-testid="lastName-input"
            error={lastNameValid === false}
            helperText={
              lastNameValid === false
                ? !!lastName
                  ? t("ERROR_LAST_NAME_INVALID")
                  : t("ERROR_LAST_NAME")
                : " "
            }
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              inputProps: { maxLength: 30 },
            }}
            onBlur={() => {
              cleanUpLastName();
              validateLastName();
            }}
            sx={{
              "& .MuiInput-underline:before": { borderBottomColor: "black" },
              "& .MuiInput-underline:after": { borderBottomColor: "black" },
            }}
            disabled={authenticated}
          />

          <TextField
            className="half-width-field"
            variant="standard"
            label={hasFirstNameLastNameTranslation ? t("FIRST_NAME_INPUT") : " "}
            value={firstName}
            onChange={(e) => {
              setFirstName(e.target.value);
            }}
            color="secondary"
            data-testid="firstName-input"
            error={firstNameValid === false}
            helperText={
              firstNameValid === false
                ? !!firstName
                  ? t("ERROR_FIRST_NAME_INVALID")
                  : t("ERROR_FIRST_NAME")
                : " "
            }
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              inputProps: { maxLength: 30 },
            }}
            onBlur={() => {
              cleanUpFirstName();
              validateFirstName();
            }}
            sx={{
              "& .MuiInput-underline:before": { borderBottomColor: "black" },
              "& .MuiInput-underline:after": { borderBottomColor: "black" },
            }}
            disabled={authenticated}
          />
        </div>

        {loggedInWithKakao ? (
          <div className="name-fields-container">
            {phoneField(true)}
            <NiceIdButton />
          </div>
        ) : (
          <>{phoneField()}</>
        )}

        {((loggedInWithKakao && i18n.exists("DATEOFBIRTH_INPUT_KAKAO")) || i18n.exists("DATEOFBIRTH_INPUT")) && (
          <>
            <div className="info-message dob-message">
              {t("DATEOFBIRTH_AGE_REQUIREMENT")}
            </div>
            <DateField
              setDay={setDobDay}
              day={dobDay}
              setMonth={setDobMonth}
              month={dobMonth}
              setYear={setDobYear}
              year={dobYear}
              label={loggedInWithKakao && i18n.exists("DATEOFBIRTH_INPUT_KAKAO") ? t("DATEOFBIRTH_INPUT_KAKAO") : t("DATEOFBIRTH_INPUT")}
              error={dobValid === false || dobAgeValid === false}
              disabled={authenticated}
              onDayBlur={cleanUpDayDOB}
              onMonthBlur={cleanUpMonthDOB}
              errorMessage={
                !dobValid
                  ? t("ERROR_INVALID_DATE")
                  : !dobAgeValid
                  ? t("ERROR_DATEOFBIRTH_AGE_VERIFICATION")
                  : " "
              }
              validateDate={validateDob}
            />
          </>
        )}

        <TextField
          variant="standard"
          label={t("EMAIL_INPUT")}
          fullWidth
          color="secondary"
          value={emailAddress}
          onChange={(e) => {
            setEmailAddress(e.target.value);
          }}
          data-testid="emailAddress-input"
          error={emailValid === false}
          helperText={emailValid === false ? t("ERROR_INVALID_EMAIL") : " "}
          InputLabelProps={{
            shrink: true,
          }}
          onBlur={() => {
            validateEmail();
          }}
          sx={{
            "& .MuiInput-underline:before": { borderBottomColor: "black" },
            "& .MuiInput-underline:after": { borderBottomColor: "black" },
          }}
        />

        <>{countryOfResidenceField()}</>

        {!loggedInWithKakao && i18n.exists("DETAILS_PAGE_AGE_DECLARATION_INPUT") && <>
          <CheckboxAgreement
          headingText={t("DETAILS_PAGE_AGE_DECLARATION_INPUT")}
          checked={ageDeclaration}
          setChecked={setAgeDeclaration}
          error={ageDeclarationValid === false && !ageDeclaration}
          errorMessage={t("ERROR_AGE_DECLARATION_MISSING")}
          />
          <br />
        </>
        }

        <CheckboxAgreement
          headingText={t("DETAILS_PAGE_CONSENT_HEADER")}
          checked={consent}
          setChecked={setConsent}
          expandable={i18n.exists("DETAILS_PAGE_CONSENT_BODY")}
          expandedElement={t("DETAILS_PAGE_CONSENT_BODY")}
          error={consentValid === false && !consent}
          errorMessage={t("ERROR_CONSENT_MISSING")}
        />
        <br />

        {i18n.exists("DETAILS_PAGE_CONSENT_CONTACT_HEADER") &&
          <>
            <CheckboxAgreement
              headingText={t("DETAILS_PAGE_CONSENT_CONTACT_HEADER")}
              checked={consentContact}
              setChecked={setConsentContact}
              expandable={i18n.exists("DETAILS_PAGE_CONSENT_CONTACT_BODY")}
              expandedElement={t("DETAILS_PAGE_CONSENT_CONTACT_BODY")}
              // error={consentContactValid === false && !consentContactValid}
              errorMessage={t("ERROR_CONSENT_MISSING")}
            />
            <br />
          </>}

        {i18n.exists("DETAILS_PAGE_CONSENT_MARKETING_HEADER") &&
          <>
            <CheckboxAgreement
              headingText={t("DETAILS_PAGE_CONSENT_MARKETING_HEADER")}
              checked={consentMarketing}
              setChecked={setConsentMarketing}
              expandable={i18n.exists("DETAILS_PAGE_CONSENT_MARKETING_BODY")}
              expandedElement={t("DETAILS_PAGE_CONSENT_MARKETING_BODY")}
              // error={consentMarketingValid === false && !consentMarketingValid}
              errorMessage={t("ERROR_CONSENT_MISSING")}
            />
            <br />
          </>
        }

        {i18n.exists("DETAILS_PAGE_CONSENT_ALL_DISCLAIMER") &&
          <>
            <h5 className="header-text">{toHtml(t("DETAILS_PAGE_CONSENT_ALL_DISCLAIMER"))}</h5>
            <br />
          </>
        }

        <Button
          variant={"contained"}
          color={"secondary"}
          fullWidth
          className={"bottom-button"}
          onClick={submitForm}
          data-testid="bottom-button"
          disabled={isAddCustomerToQueueLoading}
          aria-disabled={isAddCustomerToQueueLoading}
        >
          {t("DETAILS_NEXT_BUTTON")}
        </Button>
      </div>
    </div>
  ) : (
    <Loader />
  );
};

export default Details;
