const CountryCodeMapping = [
    {
        country: "AG",
        code: "1"
    },
    {
        country: "EG",
        code: "20"
    },
    {
        country: "SS",
        code: "211"
    },
    {
        country: "EH",
        code: "212"
    },
    {
        country: "MA",
        code: "212"
    },
    {
        country: "DZ",
        code: "213"
    },
    {
        country: "TN",
        code: "216"
    },
    {
        country: "LY",
        code: "218"
    },
    {
        country: "GM",
        code: "220"
    },
    {
        country: "SN",
        code: "221"
    },
    {
        country: "MR",
        code: "222"
    },
    {
        country: "ML",
        code: "223"
    },
    {
        country: "GN",
        code: "224"
    },
    {
        country: "CI",
        code: "225"
    },
    {
        country: "BF",
        code: "226"
    },
    {
        country: "NE",
        code: "227"
    },
    {
        country: "TG",
        code: "228"
    },
    {
        country: "BJ",
        code: "229"
    },
    {
        country: "MU",
        code: "230"
    },
    {
        country: "LR",
        code: "231"
    },
    {
        country: "SL",
        code: "232"
    },
    {
        country: "GH",
        code: "233"
    },
    {
        country: "NG",
        code: "234"
    },
    {
        country: "TD",
        code: "235"
    },
    {
        country: "CF",
        code: "236"
    },
    {
        country: "CM",
        code: "237"
    },
    {
        country: "CV",
        code: "238"
    },
    {
        country: "ST",
        code: "239"
    },
    {
        country: "GQ",
        code: "240"
    },
    {
        country: "GA",
        code: "241"
    },
    {
        country: "CG",
        code: "242"
    },
    {
        country: "CD",
        code: "243"
    },
    {
        country: "AO",
        code: "244"
    },
    {
        country: "GW",
        code: "245"
    },
    {
        country: "IO",
        code: "246"
    },
    {
        country: "AC",
        code: "247"
    },
    {
        country: "SC",
        code: "248"
    },
    {
        country: "SD",
        code: "249"
    },
    {
        country: "RW",
        code: "250"
    },
    {
        country: "ET",
        code: "251"
    },
    {
        country: "SO",
        code: "252"
    },
    {
        country: "DJ",
        code: "253"
    },
    {
        country: "KE",
        code: "254"
    },
    {
        country: "TZ",
        code: "255"
    },
    {
        country: "UG",
        code: "256"
    },
    {
        country: "BI",
        code: "257"
    },
    {
        country: "MZ",
        code: "258"
    },
    {
        country: "ZM",
        code: "260"
    },
    {
        country: "MG",
        code: "261"
    },
    {
        country: "RE",
        code: "262"
    },
    {
        country: "YT",
        code: "262"
    },
    {
        country: "ZW",
        code: "263"
    },
    {
        country: "NA",
        code: "264"
    },
    {
        country: "MW",
        code: "265"
    },
    {
        country: "LS",
        code: "266"
    },
    {
        country: "BW",
        code: "267"
    },
    {
        country: "SZ",
        code: "268"
    },
    {
        country: "KM",
        code: "269"
    },
    {
        country: "ZA",
        code: "27"
    },
    {
        country: "SH",
        code: "290"
    },
    {
        country: "TA",
        code: "290"
    },
    {
        country: "ER",
        code: "291"
    },
    {
        country: "AW",
        code: "297"
    },
    {
        country: "FO",
        code: "298"
    },
    {
        country: "GL",
        code: "299"
    },
    {
        country: "GR",
        code: "30"
    },
    {
        country: "NL",
        code: "31"
    },
    {
        country: "BE",
        code: "32"
    },
    {
        country: "FR",
        code: "33"
    },
    {
        country: "ES",
        code: "34"
    },
    {
        country: "GI",
        code: "350"
    },
    {
        country: "PT",
        code: "351"
    },
    {
        country: "LU",
        code: "352"
    },
    {
        country: "IE",
        code: "353"
    },
    {
        country: "IS",
        code: "354"
    },
    {
        country: "AL",
        code: "355"
    },
    {
        country: "MT",
        code: "356"
    },
    {
        country: "CY",
        code: "357"
    },
    {
        country: "AX",
        code: "358"
    },
    {
        country: "FI",
        code: "358"
    },
    {
        country: "BG",
        code: "359"
    },
    {
        country: "HU",
        code: "36"
    },
    {
        country: "LT",
        code: "370"
    },
    {
        country: "LV",
        code: "371"
    },
    {
        country: "EE",
        code: "372"
    },
    {
        country: "MD",
        code: "373"
    },
    {
        country: "AM",
        code: "374"
    },
    {
        country: "BY",
        code: "375"
    },
    {
        country: "AD",
        code: "376"
    },
    {
        country: "MC",
        code: "377"
    },
    {
        country: "SM",
        code: "378"
    },
    {
        country: "UA",
        code: "380"
    },
    {
        country: "RS",
        code: "381"
    },
    {
        country: "ME",
        code: "382"
    },
    {
        country: "XK",
        code: "383"
    },
    {
        country: "HR",
        code: "385"
    },
    {
        country: "SI",
        code: "386"
    },
    {
        country: "BA",
        code: "387"
    },
    {
        country: "MK",
        code: "389"
    },
    {
        country: "IT",
        code: "39"
    },
    {
        country: "VA",
        code: "39"
    },
    {
        country: "RO",
        code: "40"
    },
    {
        country: "CH",
        code: "41"
    },
    {
        country: "CZ",
        code: "420"
    },
    {
        country: "SK",
        code: "421"
    },
    {
        country: "LI",
        code: "423"
    },
    {
        country: "AT",
        code: "43"
    },
    {
        country: "GB",
        code: "44"
    },
    {
        country: "GG",
        code: "44"
    },
    {
        country: "IM",
        code: "44"
    },
    {
        country: "JE",
        code: "44"
    },
    {
        country: "DK",
        code: "45"
    },
    {
        country: "SE",
        code: "46"
    },
    {
        country: "NO",
        code: "47"
    },
    {
        country: "SJ",
        code: "47"
    },
    {
        country: "PL",
        code: "48"
    },
    {
        country: "DE",
        code: "49"
    },
    {
        country: "FK",
        code: "500"
    },
    {
        country: "BZ",
        code: "501"
    },
    {
        country: "GT",
        code: "502"
    },
    {
        country: "SV",
        code: "503"
    },
    {
        country: "HN",
        code: "504"
    },
    {
        country: "NI",
        code: "505"
    },
    {
        country: "CR",
        code: "506"
    },
    {
        country: "PA",
        code: "507"
    },
    {
        country: "PM",
        code: "508"
    },
    {
        country: "HT",
        code: "509"
    },
    {
        country: "PE",
        code: "51"
    },
    {
        country: "MX",
        code: "52"
    },
    {
        country: "CU",
        code: "53"
    },
    {
        country: "AR",
        code: "54"
    },
    {
        country: "BR",
        code: "55"
    },
    {
        country: "CL",
        code: "56"
    },
    {
        country: "CO",
        code: "57"
    },
    {
        country: "VE",
        code: "58"
    },
    {
        country: "BL",
        code: "590"
    },
    {
        country: "GP",
        code: "590"
    },
    {
        country: "MF",
        code: "590"
    },
    {
        country: "BO",
        code: "591"
    },
    {
        country: "GY",
        code: "592"
    },
    {
        country: "EC",
        code: "593"
    },
    {
        country: "GF",
        code: "594"
    },
    {
        country: "PY",
        code: "595"
    },
    {
        country: "MQ",
        code: "596"
    },
    {
        country: "SR",
        code: "597"
    },
    {
        country: "UY",
        code: "598"
    },
    {
        country: "BQ",
        code: "599"
    },
    {
        country: "CW",
        code: "599"
    },
    {
        country: "MY",
        code: "60"
    },
    {
        country: "AU",
        code: "61"
    },
    {
        country: "CC",
        code: "61"
    },
    {
        country: "CX",
        code: "61"
    },
    {
        country: "ID",
        code: "62"
    },
    {
        country: "PH",
        code: "63"
    },
    {
        country: "NZ",
        code: "64"
    },
    {
        country: "SG",
        code: "65"
    },
    {
        country: "TH",
        code: "66"
    },
    {
        country: "TL",
        code: "670"
    },
    {
        country: "NF",
        code: "672"
    },
    {
        country: "BN",
        code: "673"
    },
    {
        country: "NR",
        code: "674"
    },
    {
        country: "PG",
        code: "675"
    },
    {
        country: "TO",
        code: "676"
    },
    {
        country: "SB",
        code: "677"
    },
    {
        country: "VU",
        code: "678"
    },
    {
        country: "FJ",
        code: "679"
    },
    {
        country: "PW",
        code: "680"
    },
    {
        country: "WF",
        code: "681"
    },
    {
        country: "CK",
        code: "682"
    },
    {
        country: "NU",
        code: "683"
    },
    {
        country: "WS",
        code: "685"
    },
    {
        country: "KI",
        code: "686"
    },
    {
        country: "NC",
        code: "687"
    },
    {
        country: "TV",
        code: "688"
    },
    {
        country: "PF",
        code: "689"
    },
    {
        country: "TK",
        code: "690"
    },
    {
        country: "FM",
        code: "691"
    },
    {
        country: "MH",
        code: "692"
    },
    {
        country: "KZ",
        code: "7"
    },
    {
        country: "RU",
        code: "7"
    },
    {
        country: "JP",
        code: "81"
    },
    {
        country: "KR",
        code: "82"
    },
    {
        country: "VN",
        code: "84"
    },
    {
        country: "KP",
        code: "850"
    },
    {
        country: "HK",
        code: "852"
    },
    {
        country: "MO",
        code: "853"
    },
    {
        country: "KH",
        code: "855"
    },
    {
        country: "LA",
        code: "856"
    },
    {
        country: "CN",
        code: "86"
    },
    {
        country: "BD",
        code: "880"
    },
    {
        country: "TW",
        code: "886"
    },
    {
        country: "TR",
        code: "90"
    },
    {
        country: "IN",
        code: "91"
    },
    {
        country: "PK",
        code: "92"
    },
    {
        country: "AF",
        code: "93"
    },
    {
        country: "LK",
        code: "94"
    },
    {
        country: "MM",
        code: "95"
    },
    {
        country: "MV",
        code: "960"
    },
    {
        country: "LB",
        code: "961"
    },
    {
        country: "JO",
        code: "962"
    },
    {
        country: "SY",
        code: "963"
    },
    {
        country: "IQ",
        code: "964"
    },
    {
        country: "KW",
        code: "965"
    },
    {
        country: "SA",
        code: "966"
    },
    {
        country: "YE",
        code: "967"
    },
    {
        country: "OM",
        code: "968"
    },
    {
        country: "PS",
        code: "970"
    },
    {
        country: "AE",
        code: "971"
    },
    {
        country: "IL",
        code: "972"
    },
    {
        country: "BH",
        code: "973"
    },
    {
        country: "QA",
        code: "974"
    },
    {
        country: "BT",
        code: "975"
    },
    {
        country: "MN",
        code: "976"
    },
    {
        country: "NP",
        code: "977"
    },
    {
        country: "IR",
        code: "98"
    },
    {
        country: "TJ",
        code: "992"
    },
    {
        country: "TM",
        code: "993"
    },
    {
        country: "AZ",
        code: "994"
    },
    {
        country: "GE",
        code: "995"
    },
    {
        country: "KG",
        code: "996"
    },
    {
        country: "UZ",
        code: "998"
    }
]

export default CountryCodeMapping;