import React, { createContext, useContext, useState } from "react";
import validator from "validator";
import i18n from "../../i18n";
import { KakaoAuthContext } from "../KakaoAuthContext/KakaoAuthContext";
import { QueueDataContext } from "../QueueDataContext/QueueDataContext";
import { StoreContext } from "../StoreContext/StoreContext";

interface Props {
  children: React.ReactNode;
}

interface ValidationContextInterface {
  titleValid: boolean | undefined;
  firstNameValid: boolean | undefined;
  lastNameValid: boolean | undefined;
  telephoneValid: boolean | undefined;
  dobValid: boolean | undefined;
  dobAgeValid: boolean | undefined;
  emailValid: boolean | undefined;
  countryOfResidenceValid: boolean | undefined;
  ageDeclarationValid: boolean | undefined;
  consentValid: boolean | undefined;
  consentContactValid: boolean | undefined;
  consentMarketingValid: boolean | undefined;
  telephoneAuthenticated: boolean | undefined;
  telephoneTouched: boolean;
  setTelephoneTouched: (bool: boolean) => void;
  validateTitle: () => boolean;
  validateFirstName: () => boolean;
  validateLastName: () => boolean;
  validateDob: () => boolean;
  validateTelephone: () => boolean;
  validateEmail: () => boolean;
  validateCountryOfResidence: () => boolean;
  validateAgeDeclaration: () => boolean;
  validateConsent: () => boolean;
  validateConsentContact: () => boolean;
  validateConsentMarketing: () => boolean;
  validateAll: () => boolean;
}

export const ValidationContext = createContext<ValidationContextInterface>(
  {} as ValidationContextInterface
);

const ENABLE_NAME_VALIDATION = false;
const nameValidationRegex = new RegExp(/^[a-zA-Z]+(?:[- ]?[a-zA-Z]+)+$/);
// eslint-disable-next-line no-control-regex
const emailValidationRegex = new RegExp(
  // eslint-disable-next-line no-control-regex
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
);

const ValidationContextProvider: React.FC<Props> = ({ children }) => {
  const {
    title,
    dobDay,
    dobMonth,
    dobYear,
    firstName,
    lastName,
    telephone,
    emailAddress,
    countryOfResidence,
    ageDeclaration,
    consent,
    consentContact,
    consentMarketing,
    authenticated,
  } = useContext(QueueDataContext);
  const { languageSelection } = useContext(StoreContext);
  const { loggedInWithKakao } = useContext(KakaoAuthContext);

  const [titleValid, setTitleValid] = useState<boolean>();
  const [firstNameValid, setFirstNameValid] = useState<boolean>();
  const [lastNameValid, setLastNameValid] = useState<boolean>();
  const [telephoneValid, setTelephoneValid] = useState<boolean>();
  const [telephoneAuthenticated, setTelephoneAuthenticated] =
    useState<boolean>();
  const [telephoneTouched, setTelephoneTouched] = useState<boolean>(false);
  const [dobValid, setDobValid] = useState<boolean>();
  const [dobAgeValid, setDobAgeValid] = useState<boolean>();
  const [emailValid, setEmailValid] = useState<boolean>();
  const [countryOfResidenceValid, setCountryOfResidenceValid] =
    useState<boolean>();
  const [ageDeclarationValid, setAgeDeclarationValid] = useState<boolean>();
  const [consentValid, setConsentValid] = useState<boolean>();
  const [consentContactValid, setConsentContactValid] = useState<boolean>();
  const [consentMarketingValid, setConsentMarketingValid] = useState<boolean>();

  const nameValidation = (name?: string): boolean =>
    !!name &&
    (ENABLE_NAME_VALIDATION && languageSelection === "en" ? nameValidationRegex.test(name) : true);

  const validateFirstName = (): boolean => {
    const isValid = nameValidation(firstName || undefined);

    setFirstNameValid(isValid);
    return isValid;
  };

  const validateLastName = (): boolean => {
    const isValid = nameValidation(lastName || undefined);

    setLastNameValid(isValid);
    return isValid;
  };

  const validateTelephone = (): boolean => {
    const isValid = i18n.exists("TELEPHONE_INPUT_OPTIONAL") ? true : validator.isNumeric(telephone || "");
    setTelephoneValid(isValid);
    const isAuthenticated = loggedInWithKakao ? authenticated : true;
    setTelephoneAuthenticated(isAuthenticated);

    return isValid && isAuthenticated;
  };

  const validateDob = (): boolean => {
    if ((loggedInWithKakao && i18n.exists("DATEOFBIRTH_INPUT_KAKAO")) || i18n.exists("DATEOFBIRTH_INPUT")) {
      const dateOfBirth = `${dobYear}/${dobMonth}/${dobDay}`;
      const isDate =
        validator.isDate(dateOfBirth) &&
        new Date(dateOfBirth).toString() !== "Invalid Date";

      const dateNow = new Date();
      const maxAge = `${dateNow.getFullYear() - 150}/${
        dateNow.getMonth() + 1
      }/${dateNow.getDate()}`;
      const youngerThanMax = validator.isAfter(dateOfBirth, maxAge);
      const isValid = isDate && youngerThanMax;
      setDobValid(isValid);

      const minAge = new Date(dateNow.setFullYear(dateNow.getFullYear() - 14));
      minAge.setDate(minAge.getDate() + 1);

      const olderThanMin = validator.isBefore(
        dateOfBirth,
        `${minAge.getFullYear()}/${minAge.getMonth() + 1}/${minAge.getDate()}`
      );
      setDobAgeValid(olderThanMin);

      return isValid && olderThanMin;
    } else {
      return true;
    }
  };

  const validateEmail = (): boolean => {
    const isValid =
      !emailAddress ||
      (validator.isEmail(emailAddress) &&
        emailValidationRegex.test(emailAddress));
    setEmailValid(isValid);

    return isValid;
  };

  const validateCountryOfResidence = (): boolean => {
    if ((loggedInWithKakao && i18n.exists("COUNTRY_OF_RESIDENCE_INPUT_KAKAO")) || i18n.exists("COUNTRY_OF_RESIDENCE_INPUT")) {
      const isValid = !!countryOfResidence;
      setCountryOfResidenceValid(isValid);
      return isValid;
    } else {
      return true;
    }
  };

  const validateTitle = (): boolean => {
    if (i18n.exists("TITLE_LIST")) {
      const isValid = !!title;
      setTitleValid(isValid);
      return isValid;
    } else {
      return true;
    }
  };

  const validateAgeDeclaration = (): boolean => {
    if (!loggedInWithKakao && i18n.exists("DETAILS_PAGE_AGE_DECLARATION_INPUT")) {
      setAgeDeclarationValid(ageDeclaration);

      return ageDeclaration;
    } else {
      return true;
    }
  };

  const validateConsent = (): boolean => {
    setConsentValid(consent);

    return consent;
  };

  const validateConsentContact = (): boolean => {
    if (i18n.exists("DETAILS_PAGE_CONSENT_CONTACT_HEADER")) {
      setConsentContactValid(consentContact);

      return consentContact || true;
    } else {
      return true;
    }
  };

  const validateConsentMarketing = (): boolean => {
    if (i18n.exists("DETAILS_PAGE_CONSENT_MARKETING_HEADER")) {
      setConsentMarketingValid(consentMarketing);

      return consentMarketing || true;
    } else {
      return true;
    }
  };

  const validateAll = (): boolean => {
    setTelephoneTouched(true);
    const validTitle = validateTitle();
    const validFirstName = validateFirstName();
    const validLastName = validateLastName();
    const validTelephone = validateTelephone();
    const validDob = validateDob();
    const validEmail = validateEmail();
    const validCountryOfResidence = validateCountryOfResidence();
    const validAgeDeclaration = validateAgeDeclaration();
    const validConsent = validateConsent();
    const validConsentContact = validateConsentContact();
    const validConsentMarketing = validateConsentMarketing();

    return (
      validTitle &&
      validFirstName &&
      validLastName &&
      validTelephone &&
      validDob &&
      validEmail &&
      validCountryOfResidence &&
      validAgeDeclaration && 
      validConsent &&
      validConsentContact &&
      validConsentMarketing
    );
  };

  return (
    <ValidationContext.Provider
      value={{
        titleValid,
        firstNameValid,
        lastNameValid,
        telephoneValid,
        dobValid,
        dobAgeValid,
        emailValid,
        countryOfResidenceValid,
        ageDeclarationValid,
        consentValid,
        consentContactValid,
        consentMarketingValid,
        telephoneAuthenticated,
        telephoneTouched,
        setTelephoneTouched,
        validateTitle,
        validateDob,
        validateEmail,
        validateCountryOfResidence,
        validateFirstName,
        validateLastName,
        validateTelephone,
        validateAgeDeclaration,
        validateConsent,
        validateConsentContact,
        validateConsentMarketing,
        validateAll,
      }}
    >
      {children}
    </ValidationContext.Provider>
  );
};

export default ValidationContextProvider;
