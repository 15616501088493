export const getSessionStorageItem = (itemName: string) => {
  const saved = sessionStorage.getItem(itemName);
  return saved ? JSON.parse(saved) : undefined;
};

export const setSessionStorageItem = (item: any, itemName: string) => {
  if (item) {
    sessionStorage.setItem(itemName, JSON.stringify(item));
  } else {
    sessionStorage.removeItem(itemName);
  }
};
