import { createTheme } from "@mui/material";
import { Shadows } from "@mui/material/styles/shadows";

const theme = createTheme({
  palette: {
    primary: {
      main: '#ffffff'
    },
    secondary: {
      main: '#000000'
    }
  },
  shape: {
    borderRadius: 0,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 320,
      md: 375,
      lg: 500,
      xl: 600,
    },
  },
  typography: {
    fontFamily: [
      "Helvetica",
      "sans-serif"
    ].join(','),
  },
  shadows: Array(25).fill("none") as Shadows,
});

export default theme;
