import React, { createContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { KioskService } from "../../api/Kiosk.service";
import CountryCodeMapping from "../../constants/mappings/countryCodeMapping";
import { Question } from "../../models/Question";
import { sanitisePhoneNumber } from "../../utils/misc";
import {
  getSessionStorageItem,
  setSessionStorageItem,
} from "../../utils/storageHelper";

interface Props {
  children: React.ReactNode;
}

interface QueueDataContextInterface {
  service: string;
  setService: (service: string) => void;
  setDataFromKakao: (data: any) => void;
  title: string | null;
  setTitle: (str: string) => void;
  firstName: string | null;
  setFirstName: (str: string) => void;
  lastName: string | null;
  setLastName: (str: string) => void;
  telephone: string | null;
  setTelephone: (str: string) => void;
  emailAddress: string | null;
  setEmailAddress: (str: string) => void;
  countryOfResidence: string | null;
  setCountryOfResidence: (str: string) => void;
  queueId: number | null;
  setQueueId: (num: number) => void;
  ageDeclaration: boolean;
  setAgeDeclaration: (bool: boolean) => void;
  consent: boolean;
  setConsent: (bool: boolean) => void;
  consentContact: boolean;
  setConsentContact: (bool: boolean) => void;
  consentMarketing: boolean;
  setConsentMarketing: (bool: boolean) => void;
  dobDay: string | null;
  setDobDay: (str: string) => void;
  dobMonth: string | null;
  setDobMonth: (str: string) => void;
  dobYear: string | null;
  setDobYear: (str: string) => void;
  authenticated: boolean;
  setAuthenticated: (bool: boolean) => void;
  setVenueKioskId: (str: number) => void;
  addCustomerToQueue: () => void;
  isAddCustomerToQueueLoading: boolean;
  setIsAddCustomerToQueueLoading: (bool: boolean) => void;
  setCountryCode: (str: string) => void;
  countryCode: string;
  setQuestions: (str: Question[]) => void;
  questions: Question[];
  clearData: () => void;
}

export const QueueDataContext = createContext<QueueDataContextInterface>(
  {} as QueueDataContextInterface
);

const QueueDataContextProvider: React.FC<Props> = ({ children }) => {
  const { i18n } = useTranslation();
  const history = useHistory();

  const [service, setService] = useState(
    getSessionStorageItem("productId") || ""
  );
  const [title, setTitle] = useState<string | null>(
    getSessionStorageItem("title") || ""
  );
  const [firstName, setFirstName] = useState<string | null>(
    getSessionStorageItem("firstName") || ""
  );
  const [lastName, setLastName] = useState<string | null>(
    getSessionStorageItem("lastName") || ""
  );
  const [telephone, setTelephone] = useState<string | null>(
    getSessionStorageItem("telephone") || ""
  );
  const [dobDay, setDobDay] = useState<string | null>(
    getSessionStorageItem("dobDay") || ""
  );
  const [dobMonth, setDobMonth] = useState<string | null>(
    getSessionStorageItem("dobMonth") || ""
  );
  const [dobYear, setDobYear] = useState<string | null>(
    getSessionStorageItem("dobYear") || ""
  );
  const [emailAddress, setEmailAddress] = useState<string | null>(
    getSessionStorageItem("emailAddress") || ""
  );
  const [countryOfResidence, setCountryOfResidence] = useState<string | null>(
    getSessionStorageItem("countryOfResidence") || ""
  );
  const [auId, setAuId] = useState<string>(getSessionStorageItem("auId") || "");

  const [queueId, setQueueId] = useState<number | null>(null);
  const [ageDeclaration, setAgeDeclaration] = useState<boolean>(false);
  const [consent, setConsent] = useState<boolean>(false);
  const [consentContact, setConsentContact] = useState<boolean>(false);
  const [consentMarketing, setConsentMarketing] = useState<boolean>(false);
  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const [venueKioskId, setVenueKioskId] = useState<number>();
  const [countryCode, setCountryCode] = useState<string>(
    getSessionStorageItem("countryCode") || "KR"
  );
  const [questions, setQuestions] = useState<Question[]>(
    getSessionStorageItem("questions")
  );
  const [kakaoData, setKakaoData] = useState(
    getSessionStorageItem("kakaoData")
  );

  const baseURL = process.env.REACT_APP_KIOSK_API_BASE_URL;

  const setDataFromKakao = (data: any) => {
    setKakaoData(data);
    setLastName(data.nickname);
    setEmailAddress(data.email);
    setTelephone(sanitisePhoneNumber(data.telephone));
    setDobDay(data.birth_dd);
    setDobMonth(data.birth_mm);
    setDobYear(data.birth_yy);
    setAuId(data.au_id);
  };

  const getAnswers = () => {
    const answersArray: { questionId: number; text: string }[] = [];
    questions &&
      questions.forEach((question: Question) => {
        if (kakaoData && kakaoData[question.questionKey] === "true") {
          answersArray.push({ questionId: question.questionId, text: "Yes" });
        }
        else if (kakaoData && kakaoData[question.questionKey] === "false") {
          answersArray.push({ questionId: question.questionId, text: "No" });
        }
        else if (question.questionKey === "Date of Birth") {
          answersArray.push({
            questionId: question.questionId,
            text: `${dobYear}/${dobMonth}/${dobDay}`,
          });
        }
        else if (question.questionKey === "Over 14 Years Old") {
          answersArray.push({
            questionId: question.questionId,
            text: ageDeclaration ? "Yes" : "No",
          });
        }
        else if (question.questionKey === "Social ID") {
          answersArray.push({ questionId: question.questionId, text: auId });
        }
        else if (question.questionKey === "Kakao option") {
          if (kakaoData) {
            answersArray.push({ questionId: question.questionId, text: "Yes" });
          } else {
            answersArray.push({ questionId: question.questionId, text: "No" });
          }
        }
        else if (question.questionKey === "Country of Residence") {
          answersArray.push({
            questionId: question.questionId,
            text: countryOfResidence || "",
          });
        }
        else if (question.questionKey === "consent_notice") {
          answersArray.push({
            questionId: question.questionId,
            text: consent ? "Yes" : "No",
          });
        }
        else if (question.questionKey === "personal_data_marketing_purpose") {
          answersArray.push({
            questionId: question.questionId,
            text: consentContact ? "Yes" : "No",
          });
        }
        else if (question.questionKey === "marketing_communication") {
          answersArray.push({
            questionId: question.questionId,
            text: consentMarketing ? "Yes" : "No",
          });
        }
      });

    return JSON.stringify(answersArray);
  };

  // useEffect(() => {
  //   if (questions.length > 0) {
  //     mapAnswers();
  //   }
  // }, [questions, mapAnswers]);

  const clearData = () => {
    setSessionStorageItem(undefined, "kakaoData");
    setSessionStorageItem(undefined, "productId");
    setSessionStorageItem(undefined, "title");
    setSessionStorageItem(undefined, "firstName");
    setSessionStorageItem(undefined, "lastName");
    setSessionStorageItem(undefined, "telephone");
    setSessionStorageItem(undefined, "dobDay");
    setSessionStorageItem(undefined, "dobMonth");
    setSessionStorageItem(undefined, "emailAddress");
    setSessionStorageItem(undefined, "dobYear");
    setSessionStorageItem(undefined, "auId");
  }

  useEffect(() => {
    setSessionStorageItem(kakaoData, "kakaoData");
  }, [kakaoData]);

  useEffect(() => {
    setSessionStorageItem(service, "productId");
  }, [service]);

  useEffect(() => {
    setSessionStorageItem(title, "title");
  }, [title]);

  useEffect(() => {
    setSessionStorageItem(firstName, "firstName");
  }, [firstName]);

  useEffect(() => {
    setSessionStorageItem(lastName, "lastName");
  }, [lastName]);

  useEffect(() => {
    setSessionStorageItem(telephone, "telephone");
  }, [telephone]);

  useEffect(() => {
    setSessionStorageItem(dobDay, "dobDay");
  }, [dobDay]);

  useEffect(() => {
    setSessionStorageItem(dobMonth, "dobMonth");
  }, [dobMonth]);

  useEffect(() => {
    setSessionStorageItem(dobYear, "dobYear");
  }, [dobYear]);

  useEffect(() => {
    setSessionStorageItem(emailAddress, "emailAddress");
  }, [emailAddress]);

  useEffect(() => {
    setSessionStorageItem(auId, "auId");
  }, [auId]);

  const [isAddCustomerToQueueLoading, setIsAddCustomerToQueueLoading] =
    useState(false);
  const addCustomerToQueue = async () => {
    setIsAddCustomerToQueueLoading(true);

    try {
      const dialCountryCode = CountryCodeMapping.find(
        ({ country }) => country === countryCode
      )?.code;

      const mobileNumber = `${
        dialCountryCode ? "+" + dialCountryCode : ""
      }${telephone}`;

      const answers = getAnswers();
      const queueResponse = await KioskService.addCustomerToQueue({
        name: `${firstName} ${lastName}`,
        queueId: queueId,
        title: title,
        firstName: firstName,
        surname: lastName,
        productId: service,
        languageIsoCode: i18n.language,
        venueKioskId: venueKioskId,
        venueKioskSerial: "web",
        countryCode: countryCode,
        mobileNumber,
        emailAddress: emailAddress,
        answers,
      });
      if (
        queueResponse &&
        queueResponse.success &&
        queueResponse.success.customerInQueue
      ) {
        window.location.href = `${baseURL}/customer/${queueResponse.success.customerInQueue.customer.identifier}`;
      } else {
        history.push("/error?code=001");
        setIsAddCustomerToQueueLoading(false);
      }
    } catch (err) {
      history.push("/error?code=001");
      setIsAddCustomerToQueueLoading(false);
    }
  };

  return (
    <QueueDataContext.Provider
      value={{
        service,
        setService,
        title,
        setTitle,
        firstName,
        setFirstName,
        lastName,
        setLastName,
        emailAddress,
        setEmailAddress,
        countryOfResidence,
        setCountryOfResidence,
        telephone,
        setTelephone,
        setDataFromKakao,
        queueId,
        setQueueId,
        ageDeclaration,
        setAgeDeclaration,
        consent,
        setConsent,
        consentContact,
        setConsentContact,
        consentMarketing,
        setConsentMarketing,
        dobDay,
        setDobDay,
        dobMonth,
        setDobMonth,
        dobYear,
        setDobYear,
        authenticated,
        setAuthenticated,
        setVenueKioskId,
        addCustomerToQueue,
        isAddCustomerToQueueLoading,
        setIsAddCustomerToQueueLoading,
        countryCode,
        setCountryCode,
        setQuestions,
        questions,
        clearData,
      }}
    >
      {children}
    </QueueDataContext.Provider>
  );
};

export default QueueDataContextProvider;
