import { InputAdornment, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./DateField.scss";

interface Props {
  error?: boolean;
  errorMessage?: string;
  label: string;
  validateDate: () => void;
  day: string | null;
  month: string | null;
  year: string | null;
  setDay: (str: string) => void;
  setMonth: (str: string) => void;
  setYear: (str: string) => void;
  onDayBlur: () => void;
  onMonthBlur: () => void;
  disabled: boolean;
}

const DateField: React.FC<Props> = ({
  error,
  errorMessage,
  label,
  validateDate,
  day,
  month,
  year,
  setDay,
  setMonth,
  setYear,
  disabled,
  onDayBlur,
  onMonthBlur,
}) => {
  const { t } = useTranslation();

  const [dayTouched, setDayTouched] = useState<boolean>(false);
  const [monthTouched, setMonthTouched] = useState<boolean>(false);
  const [yearTouched, setYearTouched] = useState<boolean>(false);

  useEffect(() => {
    if (dayTouched && monthTouched && yearTouched) {
      validateDate();
    }
  }, [dayTouched, monthTouched, validateDate, yearTouched])

  return (
    <div className="date-field-container">
      <TextField
        className="year-input"
        variant="standard"
        required={true}
        color="secondary"
        error={error}
        helperText={error ? errorMessage : " "}
        disabled={disabled}
        InputProps={{
          inputProps: { maxLength: 4 },
          startAdornment: (
            <InputAdornment position="start">{t("YEAR_INPUT")}</InputAdornment>
          ),
        }}
        label={label}
        value={year}
        onChange={(e) => {
          setYear(e.target.value);
        }}
        onBlur={() => {
          setYearTouched(true);
        }}
        sx={{
          '& .MuiInput-underline:before': { borderBottomColor: 'black' },
          '& .MuiInput-underline:after': { borderBottomColor: 'black' },
        }}
      />

      <TextField
        className="month-input"
        variant="standard"
        error={error}
        color="secondary"
        disabled={disabled}
        InputProps={{
          inputProps: { maxLength: 2 },
          startAdornment: (
            <InputAdornment position="start">{t("MONTH_INPUT")}</InputAdornment>
          ),
        }}
        value={month}
        onChange={(e) => {
          setMonth(e.target.value);
        }}
        onBlur={() => {
          onMonthBlur();
          setMonthTouched(true);
        }}
        label={" "}
        sx={{
          '& .MuiInput-underline:before': { borderBottomColor: 'black' },
          '& .MuiInput-underline:after': { borderBottomColor: 'black' },
        }}
      />

      <TextField
        variant="standard"
        error={error}
        color="secondary"        
        disabled={disabled}
        InputProps={{
          inputProps: { maxLength: 2 },
          startAdornment: (
            <InputAdornment position="start">{t("DAY_INPUT")}</InputAdornment>
          ),
        }}
        value={day}
        onChange={(e) => {
          setDay(e.target.value);
        }}
        onBlur={() => {
          onDayBlur();
          setDayTouched(true);
        }}
        label={" "}
        sx={{
          '& .MuiInput-underline:before': { borderBottomColor: 'black' },
          '& .MuiInput-underline:after': { borderBottomColor: 'black' },
        }}
      />
    </div>
  );
};

export default DateField;
