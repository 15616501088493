import { ApiTranslations } from "./../models/ApiTranslations";
import i18n from "../i18n";


const getTranslationKey = (key: string) => {
  const dashIndex = key.lastIndexOf('-');

  if (dashIndex !== -1) {
    return key.substring(0, dashIndex + 1) + key[dashIndex + 1].toUpperCase() + key.substring(dashIndex + 2, key.length)
  }
  return key;
}

export const parseTranslations = (translations: ApiTranslations) => {
  for (const [key, value] of Object.entries(translations)) {
    if (key !== "undefined") {
      let translationsObject: any = {};
      value.forEach((entry: { keyword: string; value: string }) => {
        translationsObject[entry.keyword] = entry.value;
      });

      const translationKey = getTranslationKey(key);

      i18n.addResourceBundle(
        translationKey,
        "translations",
        translationsObject,
        false,
        false
      );
      i18n.reloadResources();
    }
  }
};

export const getTranslatedName = (
  nameTranslations: any,
  currentLanguage: string
) => {
  let name = "";
  for (const [key, value] of Object.entries(nameTranslations)) {
    if (key === currentLanguage) {
      name = value as string;
    }
  }
  return name;
};
