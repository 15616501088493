import "./CheckboxAgreement.scss";
import Checkbox from "../Checkbox/Checkbox";
import { useState } from "react";
import { Collapse } from "@mui/material";
import toHtml from "../../utils/toHtml";

interface Props {
    headingText: string;
    expandable?: boolean;
    checked: boolean;
    setChecked: (bool: boolean) => void;
    expandedElement?: string;
    error?: boolean;
    errorMessage?: string;
}

const CheckboxAgreement: React.FC<Props> = ({ headingText, expandable, checked, setChecked, expandedElement, error, errorMessage }) => {
    const [expanded, setExpanded] = useState<boolean>(false);

    return (
        <div className="checkbox-agreement-container">
            <div className="checkbox-agreement-header">
                <Checkbox checked={checked} setChecked={setChecked} error={error} />
                <div className="heading-button-container" onClick={() => { setExpanded(!expanded) }}>
                    <h5 className="header-text">{toHtml(headingText)}</h5>
                    {expandable ? (expanded ? <div className="arrow-up"></div> : <div className="arrow-down"></div>) : <></>}
                </div>
            </div>
            {expandable &&
                <Collapse className="expanded-section" in={expanded}>
                    {expandedElement && <h5>{toHtml(expandedElement)}</h5>}
                </Collapse>
            }
            { error && 
                <div className="error-message">{errorMessage}</div>
            }
        </div>
    );
};

export default CheckboxAgreement;

